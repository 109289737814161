import { AccountInfo, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { unpackRules } from '@casl/ability/extra';
import { useAbility } from '@casl/react';
import { useEffect, useRef } from 'react';

import { loginRequest } from '@/config/authentication';
import { environment } from '@/config/environment';
import { AbilityContext } from '@/config/providers';
import { defineTitle } from '@/lib/helpers/utils';
import { useAuth } from '@/lib/hooks/useAuth';
import { useLoginMutation } from '@/store/services/users';

export function useTokenRestore() {
  const ability = useAbility(AbilityContext);
  const { user: authedUser, logasUser, permissions } = useAuth();
  const { accounts, instance, inProgress } = useMsal();
  const [login] = useLoginMutation({ fixedCacheKey: 'login' });
  const currentAccount = accounts[0] as AccountInfo;
  const isAuthenticatedOffice = useIsAuthenticated(currentAccount);
  const loginAttemptedRef = useRef(false);

  useEffect(() => {
    if (authedUser) {
      document.title = defineTitle(
        environment.NAME,
        environment.ENV,
        authedUser.login,
        logasUser !== undefined,
      );
    }
  }, [authedUser, logasUser]);

  useEffect(() => {
    ability.update(unpackRules(permissions));
  }, [ability, permissions]);

  useEffect(() => {
    if (
      isAuthenticatedOffice &&
      !authedUser &&
      inProgress === InteractionStatus.None &&
      !loginAttemptedRef.current
    ) {
      /**
       * Prevents multiple login attempts since in React StrictMode, useEffect is called twice
       */
      loginAttemptedRef.current = true;

      instance
        .acquireTokenSilent({ ...loginRequest, account: currentAccount })
        .then(data => {
          const { accessToken, account } = data;
          login({
            login: account!.username,
            accessToken,
          });
        })
        .catch(ex => {
          if (ex instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(loginRequest);
          }
        });
    }
  }, [
    isAuthenticatedOffice,
    authedUser,
    currentAccount,
    instance,
    login,
    inProgress,
    loginAttemptedRef,
  ]);

  return { isAuthenticatedOffice, authedUser };
}
