import { Flex, HStack, Tag, Text } from '@chakra-ui/react';
import {
  chakraComponents,
  GroupBase,
  SelectComponentsConfig,
  SingleValueProps,
} from 'chakra-react-select';
import { decode } from 'he';
import React from 'react';

import { EventRoomsDeprecationWarning } from '@/components/events/Warning/Rooms/Deprecated';

import { RoomItem } from '../RoomItem';
import type { IRoomOption } from './helpers';

export const RoomMultiValue: SelectComponentsConfig<
  IRoomOption,
  true,
  GroupBase<IRoomOption>
>['MultiValue'] = React.memo(props => (
  <chakraComponents.MultiValue {...props}>
    <Flex alignItems="center">
      <Text>{props.data.value.name}</Text>
      {props.data.value.disabled && <EventRoomsDeprecationWarning />}
    </Flex>
  </chakraComponents.MultiValue>
));

export function RoomValue<IsMulti extends boolean = false>(
  props: React.PropsWithChildren<SingleValueProps<IRoomOption, IsMulti>>,
) {
  const { data } = props;

  return (
    <chakraComponents.SingleValue {...props}>
      <HStack w="full" alignItems="center" justifyContent="space-between" gap={2}>
        <RoomItem value={data.value} overflow="hidden" textOverflow="ellipsis" />
        <Tag variant="moduleCode" px={2} flexShrink={0}>
          {decode(data.value.city)}
        </Tag>
      </HStack>
    </chakraComponents.SingleValue>
  );
}
