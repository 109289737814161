import { usePopoverContext } from '@chakra-ui/react';
import { StoredEventEntry } from '@epitech/panoramix-types';
import React from 'react';

import { environment } from '@/config/environment';
import { useGetEventSanitizedQuery } from '@/lib/hooks/useGetEventSanitizedQuery';

import { MassForceRegisterRooms } from '../rooms/MassRooms';
import { MassForceRegisterStaffs } from '../staffs/Mass';

export type EventMassForceRegisterProps = {
  eventId: string;
  allSlotsIdx: number[];
  type: 'staffs' | 'rooms';
};

function _EventMassForceRegister({ eventId, allSlotsIdx, type }: EventMassForceRegisterProps) {
  const { data: event } = useGetEventSanitizedQuery<StoredEventEntry>(eventId);
  const { onClose } = usePopoverContext();

  if (!event) {
    return null;
  }

  switch (type) {
    case 'staffs':
      return <MassForceRegisterStaffs event={event} allSlotsIdx={allSlotsIdx} onSubmit={onClose} />;
    case 'rooms':
      return <MassForceRegisterRooms event={event} allSlotsIdx={allSlotsIdx} onSubmit={onClose} />;
  }
}

export const EventMassForceRegister = React.memo(_EventMassForceRegister);

if (environment.__DEV__) {
  EventMassForceRegister.displayName = 'EventMassForceRegister';
}
