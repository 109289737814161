import type { EventAppointmentEntry } from '@epitech/panoramix-types';
import type { ProjectGroupRef, Stored } from '@epitech/panoramix-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GroupOption,
  GroupValue,
  type IProjectGroupOption,
  useGroupSearch,
} from '@/components/groups/select';
import { SearchMenu } from '@/components/ui-elements/SearchMenu/Single';
import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { environment } from '@/config/environment';
import { useForceRegisterToEventMutation } from '@/store/services/events';

export type ForceRegisterGroupAppointmentSingleProps = {
  event: Stored<EventAppointmentEntry>;
  onClose: () => void;
  slotIdx: number;
};

function _ForceRegisterGroupAppointmentSingle({
  event,
  onClose,
  slotIdx,
}: ForceRegisterGroupAppointmentSingleProps) {
  const searchProjectGroups = useGroupSearch(event.moduleRef);
  const [forceRegisterToEvent, { isLoading: isRegisterLoading }] =
    useForceRegisterToEventMutation();
  const { t } = useTranslation('components/events');

  const onSubmitHandler = useCallback(
    (selectedGroup: IProjectGroupOption['value']) => {
      const selectedGroupRef: ProjectGroupRef[] = [];
      if (selectedGroup)
        selectedGroupRef.push({ ...selectedGroup, tpIds: selectedGroup.tpIds || [] });
      forceRegisterToEvent({
        eventId: event._id!,
        toRegisters: selectedGroupRef,
        slotIdx,
        type: 'projectGroups',
      });
      onClose();
    },
    [onClose, event._id, forceRegisterToEvent, slotIdx],
  );

  const filterOption = useCallback(
    (option: FilterOptionOption<IProjectGroupOption>) => {
      return !event?.slots.some(slot => slot.registeredGroup?._id === option.data.value._id);
    },
    [event?.slots],
  );

  const onNoOptions = useCallback(() => t('empty_group-search'), [t]);

  return (
    <SearchMenu<IProjectGroupOption>
      name="forceRegisterGroup"
      loadOptions={searchProjectGroups}
      onSubmit={onSubmitHandler}
      filterOption={filterOption}
      isLoading={isRegisterLoading}
      noOptionsMessage={onNoOptions}
      htmlDecode={true}
      components={{ Option: GroupOption, SingleValue: GroupValue }}
      infiniteScroll={true}
    />
  );
}

export const ForceRegisterGroupAppointmentSingle = React.memo(_ForceRegisterGroupAppointmentSingle);

if (environment.__DEV__) {
  ForceRegisterGroupAppointmentSingle.displayName = 'ForceRegisterGroupAppointmentSingle';
}
