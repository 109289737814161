import {
  Box,
  List,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsProps,
} from '@chakra-ui/react';
import { ProjectGroupRef, UserRef } from '@epitech/panoramix-types';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RegisteredGroupItem, RegisteredStaffItem } from '@/components/events';
import { RegisteredUserItem } from '@/components/events/registered/User';
import { environment } from '@/config/environment';

type ForceRegisterTabListWrapperProps = {
  eventId: string;
  slotIdx: number;
  isAddDisabled?: boolean;
  tabsProps?: Partial<TabsProps>;
} & (
  | {
      type: 'users' | 'staffs';
      registeredItems: UserRef[];
    }
  | {
      type: 'projectGroups';
      registeredItems: ProjectGroupRef[];
    }
);

function _ForceRegisterTabListWrapper({
  tabsProps,
  ...props
}: PropsWithChildren<ForceRegisterTabListWrapperProps>) {
  const { t } = useTranslation('components/events');

  const registeredItems = useMemo(() => {
    switch (props.type) {
      case 'users':
        return props.registeredItems.map(user => (
          <RegisteredUserItem
            as={ListItem}
            hasPortal={false}
            canForceUnregister={true}
            eventId={props.eventId}
            key={user._id}
            user={user}
            slotIdx={props.slotIdx}
            unregisterBtnOnHover={false}
          />
        ));
      case 'staffs':
        return props.registeredItems.map(staff => (
          <RegisteredStaffItem
            canForceUnregister={true}
            hasPortal={false}
            key={staff._id}
            user={staff}
            eventId={props.eventId}
            slotIdx={props.slotIdx}
            unregisterBtnOnHover={false}
          />
        ));
      case 'projectGroups':
        return props.registeredItems.map(group => (
          <RegisteredGroupItem
            as={ListItem}
            hasPortal={false}
            canForceUnregister={true}
            eventId={props.eventId}
            key={group._id}
            group={group}
            slotIdx={props.slotIdx}
            unregisterBtnOnHover={false}
          />
        ));
      default:
        return null;
    }
  }, [props.type, props.registeredItems, props.eventId, props.slotIdx]);

  return (
    <Tabs
      size="md"
      width="full"
      variant="line"
      isFitted
      defaultIndex={props.isAddDisabled ? 1 : 0}
      {...tabsProps}
    >
      {/** We have to adjust for the Popover stack spacing */}
      <TabList mt={-4}>
        <Tab isDisabled={props.isAddDisabled}>{t('add')}</Tab>
        <Tab>{t('list')}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>{props.children}</TabPanel>
        <TabPanel as={List} variant="bordered">
          {registeredItems && registeredItems.length > 0 ? (
            registeredItems.map(registeredItem => registeredItem)
          ) : (
            <Box textAlign="center">{t('empty')}</Box>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export const ForceRegisterTabListWrapper = React.memo(_ForceRegisterTabListWrapper);

if (environment.__DEV__) {
  ForceRegisterTabListWrapper.displayName = 'ForceRegisterTabListWrapper';
}
