import { useContext } from 'react';

import EventDetailsContext from './DetailsContext';

export function useEventDetailsContext() {
  const context = useContext(EventDetailsContext);

  if (context === undefined) {
    throw new Error('useEventDetailsProvider must be used within a EventDetailsProvider');
  }

  return context;
}
