import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import type { RoomRef } from '@epitech/panoramix-types';
import type { SerializedError } from '@reduxjs/toolkit';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/config/environment';

import { EventCourseWarning } from './Course';
import { isWarningError } from './helpers';
import { EventRoomsWarning } from './Rooms';
import { EventSlotsWarning } from './Slots';

export type EventConfirmWarningProps = {
  isGroupMode: boolean;
  error?: FetchBaseQueryError | SerializedError;
  roomsRefSelected?: RoomRef[];
  onConfirm: (openAfter?: boolean) => void;
  onCancel: () => void;
  confirmTitle: string;
  descriptionType?: 'edit' | 'delete';
};

function _EventConfirmWarning({
  error,
  roomsRefSelected,
  onConfirm,
  onCancel,
  isGroupMode,
  confirmTitle,
  descriptionType,
}: EventConfirmWarningProps) {
  const { t } = useTranslation('actions');
  const { t: tForm } = useTranslation('components/event-form');
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  if (!isWarningError(error)) {
    return null;
  }

  const { deletedSlots, eventsConflicting, unregisteredByEdit } = error.data;

  if (
    !deletedSlots?.length &&
    !eventsConflicting?.length &&
    (!unregisteredByEdit ||
      (!unregisteredByEdit.registeredStaffs &&
        !unregisteredByEdit.registeredGroups &&
        !unregisteredByEdit.registeredUsers))
  ) {
    return null;
  }

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onConfirm(false);
  };

  const onSubmitAndOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onConfirm(true);
  };

  return (
    <AlertDialog isOpen={true} onClose={onCancel} size="3xl" leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent
          as="form"
          onSubmit={e => e.preventDefault()}
          minW={eventsConflicting?.length ? '1000px' : 'fit-content'}
        >
          <AlertDialogHeader>{confirmTitle}</AlertDialogHeader>
          <AlertDialogBody
            as={VStack}
            gap={4}
            w="full"
            divider={<StackDivider />}
            fontSize="sm"
            alignItems="stretch"
          >
            {deletedSlots?.length > 0 && descriptionType && (
              <EventSlotsWarning
                deletedSlots={deletedSlots}
                isGroupMode={isGroupMode}
                type={descriptionType}
              />
            )}
            {eventsConflicting?.length > 0 && (
              <EventRoomsWarning
                eventsConflicting={eventsConflicting}
                selectedRooms={roomsRefSelected}
              />
            )}
            {((unregisteredByEdit?.registeredGroups &&
              unregisteredByEdit?.registeredGroups.length > 0) ||
              (unregisteredByEdit?.registeredStaffs &&
                unregisteredByEdit?.registeredStaffs.length > 0) ||
              (unregisteredByEdit?.registeredUsers &&
                unregisteredByEdit?.registeredUsers.length > 0)) && (
              <EventCourseWarning unregisteredByEdit={unregisteredByEdit} />
            )}
          </AlertDialogBody>
          <AlertDialogFooter as={ButtonGroup} size="sm">
            <Button ref={cancelRef} onClick={onCancel} colorScheme="gray">
              {t('action_cancel')}
            </Button>
            <Button onClick={onSubmit} colorScheme="blue" name="submit" type="submit">
              {t('action_confirm')}
            </Button>
            <Button
              onClick={onSubmitAndOpen}
              colorScheme="blue"
              name="submit_and_open"
              type="submit"
            >
              {t('action_confirm')} {tForm('submit_and_open')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export const EventConfirmWarning = React.memo(_EventConfirmWarning);

if (environment.__DEV__) {
  EventConfirmWarning.displayName = 'EventConfirmWarning';
}
