import {
  HStack,
  IconButton,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react';
import { EventSlotEntry } from '@epitech/panoramix-types';
import { ActivityRef, ModuleRef, ProjectGroupRef } from '@epitech/panoramix-types';
import { format } from 'date-fns';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FiArrowLeft } from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';

import { GoToExternalGroupSource } from '@/components/groups';
import { RawEvent } from '@/store/services/events';
import { useGetFollowUpByProjectGroupQuery } from '@/store/services/modules';

import FollowUpFormContent from './Form';
import SkeletonForm from './Skeleton/Form';

interface IProps {
  moduleRef: ModuleRef;
  activityRef: ActivityRef;
  projectGroupRef: ProjectGroupRef;
  slot: EventSlotEntry;
  event: RawEvent;
}

function _FollowUpForm({ moduleRef, activityRef, projectGroupRef, slot, event }: IProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation('components/followups/followups');
  const { t: tShared } = useTranslation('components/shared');

  const loadedFormat = t('date_header_format');
  const dateFormat = loadedFormat === 'date_header_format' ? 'dd/MM HH:mm a' : loadedFormat;

  const { data: followUp, isLoading } = useGetFollowUpByProjectGroupQuery(
    {
      moduleId: moduleRef._id,
      activityId: activityRef._id,
      projectGroupId: projectGroupRef._id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const onClose = useCallback(() => {
    navigate({
      pathname: `/calendar/events/${event._id}/details`,
      search: location.search,
    });
  }, [event._id, location.search, navigate]);

  useEffect(() => {
    if (!isLoading && !followUp) {
      navigate({
        pathname: `/calendar/events/${event._id}/details`,
        search: location.search,
      });
    }
  }, [event._id, location.search, navigate, followUp, isLoading]);

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="2xl"
      closeOnOverlayClick={false}
      motionPreset={isLoading ? 'none' : 'scale'}
    >
      <ModalOverlay />
      <ModalContent padding={0} position="relative">
        <HStack justify="space-between" width="full" px={6} align="center">
          <ModalHeader fontSize="2xl" as="h1" flex={1} px={0}>
            {format(slot.start, dateFormat)} - {projectGroupRef.name}
          </ModalHeader>
          <GoToExternalGroupSource eventId={event._id} registeredGroup={projectGroupRef} />
          <Tooltip label={tShared('action_back_event')}>
            <IconButton
              aria-label={tShared('action_back_event')}
              icon={<FiArrowLeft />}
              variant="ghost"
              onClick={onClose}
              mr={-4}
            />
          </Tooltip>
        </HStack>
        {isLoading ? (
          <SkeletonForm />
        ) : followUp ? (
          <FollowUpFormContent
            moduleId={moduleRef._id}
            activityId={activityRef._id}
            projectGroupId={projectGroupRef._id}
            onClose={onClose}
            followUp={followUp}
          />
        ) : (
          <SkeletonForm />
        )}
      </ModalContent>
    </Modal>
  );
}

const FollowUpForm = React.memo(_FollowUpForm);
export default FollowUpForm;
