import { AvatarProps, HStack, StackProps, Text } from '@chakra-ui/react';
import type { UserRef } from '@epitech/panoramix-types';
import React from 'react';

import { UserAvatar } from './UserAvatar';

export type UserItemProps = {
  /** Spacing between the Avatar and the name. Defaults to 2. (Check chakra styles to convert in `px`) */
  avatarSize?: AvatarProps['size'];
  value: UserRef;
} & StackProps;

function _UserItem({
  value: user,
  spacing = 2,
  avatarSize = 'xs',
  ...stackProps
}: UserItemProps): React.ReactElement {
  const formattedLogin = user.login.split('@')[0].replace('.', ' ');
  return (
    <HStack
      gap={spacing}
      alignItems="center"
      flex={1}
      fontSize="sm"
      flexWrap="nowrap"
      minW="fit-content"
      {...stackProps}
    >
      <UserAvatar user={user} size={avatarSize} />
      <Text
        title={`${user.firstName} ${user.lastName}`}
        overflow="hidden"
        textOverflow={'ellipsis'}
        textTransform="capitalize"
      >
        {formattedLogin}
      </Text>
    </HStack>
  );
}

export const UserItem = React.memo<UserItemProps>(_UserItem);
