import { Tag, TagLeftIcon, Tooltip } from '@chakra-ui/react';
import { HistoryEntry } from '@epitech/panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle } from 'react-icons/fi';

import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';

interface EventHistoryLogasUserItemProps {
  userRef: HistoryEntry['userRef'];
  logasUserRef: HistoryEntry['logasUserRef'];
}

function _EventHistoryLogasUserItem({ userRef, logasUserRef }: EventHistoryLogasUserItemProps) {
  const { t } = useTranslation('components/events/history');

  return logasUserRef ? (
    <Tooltip
      label={t('logged_as_from', {
        firstName: logasUserRef.firstName,
        lastName: logasUserRef.lastName,
      })}
      minW="max-content"
    >
      <Tag colorScheme="orange">
        <TagLeftIcon as={FiAlertTriangle} />
        <UserItem value={userRef} avatarSize="2xs" />
      </Tag>
    </Tooltip>
  ) : (
    <UserItem value={userRef} avatarSize="2xs" />
  );
}

export const EventHistoryLogasUserItem = React.memo(_EventHistoryLogasUserItem);

if (environment.__DEV__) {
  EventHistoryLogasUserItem.displayName = 'EventHistoryLogasUserItem';
}
