import { Wrap } from '@chakra-ui/react';
import { EventEntry } from '@epitech/panoramix-types';
import React from 'react';

import { environment } from '@/config/environment';

import { EventDetailsCohortGroup } from './Group';

interface EventDetailsCohortsProps {
  cohorts: EventEntry['cohortGroups'];
}

function _EventDetailsCohorts({ cohorts }: EventDetailsCohortsProps) {
  const sortedCohorts = React.useMemo(
    () =>
      [...cohorts].sort((a, b) => {
        if (!a.city && b.city) {
          return 1; // Place a after b
        }
        if (a.city && !b.city) {
          return -1; // Place a before b
        }
        if (!a.city && !b.city) {
          return 0; // No change in order
        }

        if (a.city && b.city) {
          if (a.city.name === b.city.name) {
            const nbOfCohortsA = Object.values(a).filter(Boolean).length;
            const nbOfCohortsB = Object.values(b).filter(Boolean).length;
            return nbOfCohortsB - nbOfCohortsA;
          }
          return a.city.name.localeCompare(b.city.name);
        }
        return 0; // No change in order
      }),
    [cohorts],
  );

  return (
    <Wrap spacing={2}>
      {sortedCohorts.map((cohortGroup, idx) => (
        <EventDetailsCohortGroup key={idx} cohortGroup={cohortGroup} />
      ))}
    </Wrap>
  );
}

export const EventDetailsCohorts = React.memo(_EventDetailsCohorts);

if (environment.__DEV__) {
  EventDetailsCohorts.displayName = 'EventDetailsCohorts';
}
