import type { GroupingRef, ProjectGroupRef } from '@epitech/panoramix-types';
import { ModuleEntry } from '@epitech/panoramix-types';
import type { OptionBase } from 'chakra-react-select';

export interface IProjectGroupOption extends OptionBase {
  label: string;
  value: ProjectGroupRef;
}

export interface IGroupingOption extends OptionBase {
  label: string;
  value: GroupingRef;
}

export const getProjectGroupOption = (projectGroup: ProjectGroupRef): IProjectGroupOption => ({
  value: projectGroup,
  label: projectGroup.name,
});

export const getGroupingsOptions = (module: ModuleEntry) =>
  module.groupingsRef && module.groupingsRef.length > 0
    ? module.groupingsRef?.map(groupingRef => ({
        value: groupingRef,
        label: groupingRef.name,
      }))
    : [];
