import { useToast } from '@chakra-ui/react';
import type { EventCourseEntry } from '@epitech/panoramix-types';
import type { Stored } from '@epitech/panoramix-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { type IUserOption } from '@/components/users/select';
import { environment } from '@/config/environment';
import { useForceRegisterToEventMutation } from '@/store/services/events';

import { ForceRegisterStaffsBase } from './Base';

export type ForceRegisterStaffsCourseProps = {
  event: Stored<EventCourseEntry>;
  onClose: () => void;
};

function _ForceRegisterStaffsCourse({ event, onClose }: ForceRegisterStaffsCourseProps) {
  const [forceRegisterStaffsToEvent, { isLoading: isRegisterLoading }] =
    useForceRegisterToEventMutation();
  const { t } = useTranslation('components/events');
  const toast = useToast();

  const onSubmitHandler = useCallback(
    (selectedOptions: IUserOption[]) => {
      const selectedUsersRefs = selectedOptions.map(opt => opt.value);

      forceRegisterStaffsToEvent({
        eventId: event._id!,
        toRegisters: selectedUsersRefs,
        type: 'staffs',
      });
      toast({
        description: t('staff_register_success'),
        status: 'success',
        position: 'top',
      });
      onClose();
    },
    [onClose, event._id, forceRegisterStaffsToEvent, t, toast],
  );

  const filterOption = useCallback(
    (option: FilterOptionOption<IUserOption>) => {
      return !event.registeredStaffs?.some(
        registeredStaff => registeredStaff._id === option.data.value._id,
      );
    },
    [event.registeredStaffs],
  );

  return (
    <ForceRegisterStaffsBase
      filterOption={filterOption}
      onSubmit={onSubmitHandler}
      isLoading={isRegisterLoading}
      searchParams={{
        cohortGroups: event.cohortGroups,
        moduleRef: event.moduleRef,
      }}
    />
  );
}

export const ForceRegisterStaffsCourse = React.memo(_ForceRegisterStaffsCourse);

if (environment.__DEV__) {
  ForceRegisterStaffsCourse.displayName = 'ForceRegisterStaffsCourse';
}
