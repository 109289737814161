import { useToast } from '@chakra-ui/react';
import type { StoredEventEntry } from '@epitech/panoramix-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchMenuMulti } from '@/components/ui-elements/SearchMenu/Multi';
import { getStaffOption, type IUserOption, UserOption } from '@/components/users/select';
import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';
import { useForceRegisterToEventMutation } from '@/store/services/events';
import { useLazySearchStaffsQuery } from '@/store/services/users';

type MassForceRegisterStaffsProps = {
  event: StoredEventEntry;
  allSlotsIdx: number[];
  onSubmit: () => void;
};

function _MassForceRegisterStaffs({ event, onSubmit, allSlotsIdx }: MassForceRegisterStaffsProps) {
  const [forceRegisterStaffs, { isLoading: isRegisterLoading }] = useForceRegisterToEventMutation();
  const { t } = useTranslation('components/events');
  const [searchStaffs] = useLazySearchStaffsQuery();
  const toast = useToast();
  const onStaffSearch = useCallback(
    async (
      search: string,
      _callback?: (options: IUserOption[]) => void | Promise<IUserOption[]>,
      limit?: number,
      offset?: number,
    ) => {
      const result = await searchStaffs(
        {
          search,
          moduleRef: event?.moduleRef,
          cohortGroups: event.cohortGroups,
          limit,
          offset,
        },
        true,
      ).unwrap();
      return result.map(getStaffOption);
    },
    [event.moduleRef, event.cohortGroups, searchStaffs],
  );

  const onSubmitHandler = useCallback(
    async (selectedOptions: IUserOption[]) => {
      const selectedUsersRefs = selectedOptions.map(opt => opt.value);

      await forceRegisterStaffs({
        eventId: event._id!,
        type: 'staffs',
        toRegisters: selectedUsersRefs,
        slotIdx: allSlotsIdx,
      }).unwrap();
      toast({
        description: t('mass_staff_register_success'),
        status: 'success',
        position: 'top',
      });
      onSubmit();
    },
    [onSubmit, toast, event._id, allSlotsIdx, forceRegisterStaffs, t],
  );

  const onNoOptions = useCallback(() => t('empty_user-search'), [t]);

  return (
    <SearchMenuMulti<IUserOption>
      name="massForceRegisterStaffs"
      loadOptions={onStaffSearch}
      onSubmit={onSubmitHandler}
      components={{
        Option: UserOption,
        Value: UserItem,
      }}
      isLoading={isRegisterLoading}
      noOptionsMessage={onNoOptions}
      infiniteScroll={true}
    />
  );
}

export const MassForceRegisterStaffs = React.memo(_MassForceRegisterStaffs);

if (environment.__DEV__) {
  MassForceRegisterStaffs.displayName = 'MassForceRegisterStaffs';
}
