import { Alert, AlertIcon, VStack } from '@chakra-ui/react';
import { EventSlotEntry } from '@epitech/panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { sanitizeDatePayload } from '@/lib/helpers/dates.helpers';

import { SlotPreviewList } from '../slots/PreviewList/PreviewList';
import type { EventWarningPayload, WarningType } from './types';

interface IEventSlotWarningProps {
  deletedSlots: EventWarningPayload['deletedSlots'];
  isGroupMode: boolean;
  type: WarningType;
}

function _EventSlotsWarning({
  deletedSlots: rawDeletedSlots,
  isGroupMode,
  type,
}: IEventSlotWarningProps) {
  const { t } = useTranslation('components/warnings');

  const deletedSlots = rawDeletedSlots.map(sanitizeDatePayload<EventSlotEntry>);

  return (
    <VStack spacing={2} boxShadow="inner">
      <Alert status="warning" variant="subtle" borderTopRadius="md">
        <AlertIcon />
        {t(`${type}_slot_warning`)}
      </Alert>
      <SlotPreviewList
        slots={deletedSlots}
        isGroupMode={isGroupMode}
        borderTopRadius={0}
        borderBottomRadius="md"
      />
    </VStack>
  );
}

export const EventSlotsWarning = React.memo(_EventSlotsWarning);
