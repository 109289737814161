import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Icon,
  IconButton,
  Tag,
  TagLeftIcon,
  Text,
  Tooltip,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import type { EventSample } from '@epitech/panoramix-types';
import type { RoomRef } from '@epitech/panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { LabelData } from '@/components/ui-elements/LabelData';

import { EventDetailsDate } from '../../details/Date';

interface IEventRoomsWarningItemProps {
  event: EventSample;
  getConflictingRooms: (rooms: RoomRef[]) => RoomRef[];
}

function _EventRoomsWarningItem({ event, getConflictingRooms }: IEventRoomsWarningItemProps) {
  const { t } = useTranslation('components/events');
  const conflictingRooms = getConflictingRooms(event.roomsRef).map(room => room._id);

  return (
    <Card
      w="full"
      layerStyle="container"
      align="start"
      py={2}
      px={4}
      gap={4}
      boxShadow="inner"
      borderRadius={0}
      _last={{ borderBottomRadius: 'md' }}
    >
      <CardHeader p={0} as={Wrap} align="center" justify="space-between" w="full">
        <WrapItem fontSize="md" fontWeight="semibold">
          {event.title}
        </WrapItem>
        {event.moduleRef && (
          <WrapItem>
            <HStack spacing={2} w="full">
              <Badge size="sm" colorScheme="blue" fontFamily="mono">
                {event.moduleRef.name}
              </Badge>
              {event.moduleRef?.activityRef && (
                <>
                  <Icon as={FiChevronRight} color="gray.500" />
                  <Badge size="sm" colorScheme="teal" fontFamily="mono">
                    {event.moduleRef?.activityRef.name}
                  </Badge>
                </>
              )}
            </HStack>
          </WrapItem>
        )}
      </CardHeader>
      <CardBody p={0} as={HStack} gap={2} fontSize="sm" w="full">
        <VStack gap={2} fontSize="sm" flex={1}>
          <LabelData label={t('date')}>
            <EventDetailsDate start={event.start} end={event.end} />
          </LabelData>
          {event.seats && event.seats > 0 && (
            <LabelData label={t('seats')}>
              <Text fontFamily="mono">{event.seats}</Text>
            </LabelData>
          )}
          <LabelData label={t('rooms')}>
            <HStack spacing={2}>
              {event.roomsRef.map(room =>
                conflictingRooms.includes(room._id) ? (
                  <Tag key={room._id} colorScheme="orange" size="sm">
                    <TagLeftIcon boxSize="12px" as={FiAlertTriangle} />
                    {room.name}
                  </Tag>
                ) : (
                  <Text key={room._id}>{room.name}</Text>
                ),
              )}
            </HStack>
          </LabelData>
        </VStack>
        <Tooltip aria-label={t('view_details_label')} label={t('view_details_label')}>
          <IconButton
            aria-label={t('view_details_label')}
            size="xs"
            as={Link}
            to={`/calendar/events/${event._id}/details`}
            justifySelf="end"
            alignSelf="end"
            icon={<Icon as={FiChevronRight} />}
          />
        </Tooltip>
      </CardBody>
    </Card>
  );
}

export const EventRoomsWarningItem = React.memo(_EventRoomsWarningItem);
