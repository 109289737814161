import { Alert, FormControl, FormLabel, HStack, Icon, IconButton, VStack } from '@chakra-ui/react';
import { EventEntry } from '@epitech/panoramix-types';
import { CohortGroup } from '@epitech/panoramix-types';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

import { environment } from '@/config/environment';

import { CohortGroupFormElement } from './GroupFormElement';
import { findCohortFromType, getCohortGroupMatcher } from './helpers';

interface IFormCohortListProps {
  cohortGroupsAllowed?: CohortGroup[];
  isLoading?: boolean;
  label?: React.ReactElement;
  warningOnEmpty?: boolean;
}

function _CohortGroupFormList({
  cohortGroupsAllowed = [],
  isLoading = false,
  warningOnEmpty = false,
  label: Label,
}: IFormCohortListProps) {
  const { t } = useTranslation('components/event-form');
  const { formState } = useFormContext<EventEntry>();
  const { fields, append, remove } = useFieldArray({
    name: 'cohortGroups',
  });

  const cityOptions = useMemo(
    () => findCohortFromType(cohortGroupsAllowed, 'city', 1),
    [cohortGroupsAllowed],
  );
  const curriculumOptions = useMemo(
    () => findCohortFromType(cohortGroupsAllowed, 'curriculum', 1),
    [cohortGroupsAllowed],
  );
  const promotionOptions = useMemo(
    () => findCohortFromType(cohortGroupsAllowed, 'promotion', -1),
    [cohortGroupsAllowed],
  );

  const errorCohorts = formState.errors.cohortGroups;
  const cohortGroups: CohortGroup[] = useWatch({ name: 'cohortGroups' });

  const noValidCohortGroup = useMemo(
    () =>
      warningOnEmpty &&
      (!cohortGroups?.length ||
        !cohortGroups?.some((cg) => cg.city || cg.curriculum || cg.promotion)),
    [cohortGroups, warningOnEmpty],
  );

  const cohortGroupMatcher = useMemo(
    () => getCohortGroupMatcher(cohortGroupsAllowed),
    [cohortGroupsAllowed],
  );

  const isAddDisabled =
    cohortGroups?.some((cg) => !cg.city && !cg.curriculum && !cg.promotion) ||
    cohortGroupsAllowed.length === fields.length;

  return (
    <FormControl isInvalid={errorCohorts !== undefined}>
      <HStack justify="space-between" align="flex-start">
        {Label ? Label : <FormLabel>{t('cohort')}</FormLabel>}
        <IconButton
          colorScheme="blue"
          aria-label="Add cohorts group"
          icon={<Icon as={FiPlus} />}
          type="button"
          size="xs"
          onClick={() => append({})}
          isDisabled={isLoading || isAddDisabled}
        />
      </HStack>
      {noValidCohortGroup && (
        <Alert status="warning" borderRadius="sm" fontSize="sm" my="1">
          {t('empty_cohort_group')}
        </Alert>
      )}
      <VStack spacing={2}>
        {cohortGroupsAllowed &&
          fields.map((it, idx) => (
            <CohortGroupFormElement
              key={it.id}
              indexInCG={idx}
              cohortGroupMatcher={cohortGroupMatcher}
              curriculumOptions={curriculumOptions}
              cityOptions={cityOptions}
              promotionOptions={promotionOptions}
              remove={remove}
              errorCohorts={errorCohorts}
            />
          ))}
      </VStack>
    </FormControl>
  );
}

export const CohortGroupFormList = React.memo(_CohortGroupFormList);

if (environment.__DEV__) {
  CohortGroupFormList.displayName = 'CohortGroupFormList';
}
