import {
  FocusLock,
  IconButtonProps,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  StackDivider,
  Text,
  usePopoverContext,
  VStack,
} from '@chakra-ui/react';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetEventSanitizedQuery } from '@/lib/hooks/useGetEventSanitizedQuery';

import { EventForceRegister, EventForceRegisterProps } from '../ForceRegister';
import type { EventSlotEntry } from '@epitech/panoramix-types';
import { IconType } from 'react-icons/lib';

export interface EventForceRegisterActionButtonContentProps
  extends Omit<React.ComponentPropsWithoutRef<typeof EventForceRegister>, 'onClose'> {
  eventId: string;
  hasPortal?: boolean;
  slot?: EventSlotEntry;
  slotIdx?: number;
  userType: EventForceRegisterProps['userType'];
  isDisabled?: boolean;
  buttonProps?: Partial<IconButtonProps>;
  icon?: IconType;
}

function _EventForceRegisterActionButtonContent({
  eventId,
  slotIdx,
  userType,
  ...forceRegisterProps
}: EventForceRegisterActionButtonContentProps): React.ReactElement | null {
  const { t } = useTranslation('components/events/force-register');
  const { data: event } = useGetEventSanitizedQuery(eventId);
  const { onClose } = usePopoverContext();

  if (!event) {
    return null;
  }

  return (
    <FocusLock restoreFocus={true}>
      <VStack spacing={4} w="full" divider={<StackDivider />}>
        <PopoverHeader textStyle="popover-header" px={0} py={0} w="full" display="flex">
          <PopoverCloseButton p={0} top={3.5} />
          <Text>{t(`menu_title_${userType}`)}</Text>
        </PopoverHeader>
        <PopoverBody minW="full">
          <EventForceRegister
            userType={userType}
            onClose={onClose}
            eventId={eventId}
            slotIdx={slotIdx}
            {...forceRegisterProps}
          />
        </PopoverBody>
      </VStack>
    </FocusLock>
  );
}

export const EventForceRegisterActionButtonContent = React.memo(
  _EventForceRegisterActionButtonContent,
);
