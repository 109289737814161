import { StoredEventEntry } from '@epitech/panoramix-types';
import React from 'react';

import { environment } from '@/config/environment';
import { useGetEventSanitizedQuery } from '@/lib/hooks/useGetEventSanitizedQuery';

import { ForceRegisterGroups } from './groups';
import { ForceRegisterRooms } from './rooms/Rooms';
import { ForceRegisterStaffs } from './staffs/Staffs';
import { ForceRegisterUsers } from './users';

export type EventForceRegisterProps = {
  eventId: string;
  slotIdx?: number;
  userType: 'staffs' | 'room' | 'groups' | 'users';
  onClose: () => void;
};

function _EventForceRegister({ onClose, eventId, slotIdx, userType }: EventForceRegisterProps) {
  const { data: event } = useGetEventSanitizedQuery<StoredEventEntry>(eventId);

  if (!event) return null;

  switch (userType) {
    case 'staffs':
      return <ForceRegisterStaffs event={event} slotIdx={slotIdx} onClose={onClose} />;
    case 'groups':
      return <ForceRegisterGroups event={event} onClose={onClose} slotIdx={slotIdx} />;
    case 'users':
      return <ForceRegisterUsers event={event} onClose={onClose} slotIdx={slotIdx} />;
    case 'room':
      return <ForceRegisterRooms event={event} slotIdx={slotIdx} onClose={onClose} />;
    default:
      return null;
  }
}

export const EventForceRegister = React.memo(_EventForceRegister);

if (environment.__DEV__) {
  EventForceRegister.displayName = 'EventForceRegister';
}
