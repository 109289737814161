import { EventSlotEntry } from '@epitech/panoramix-types';
import React from 'react';
import { FiEdit2 } from 'react-icons/fi';

import { EventForceRegisterActionButton } from '@/components/events/force-register';
import { environment } from '@/config/environment';
import { BASE_OPACITY_TRANSITION } from '@/config/theme';

type ForceRegisterStaffsActionButtonProps = {
  eventId: string;
  slot: EventSlotEntry;
  slotIdx: number;
};

function _ForceRegisterStaffActionButton({
  eventId,
  slot,
  slotIdx,
}: ForceRegisterStaffsActionButtonProps) {
  return (
    <EventForceRegisterActionButton
      icon={FiEdit2}
      userType="staffs"
      eventId={eventId}
      slot={slot}
      slotIdx={slotIdx}
      buttonProps={{
        opacity: 0,
        _groupHover: {
          opacity: 1,
        },
        transition: BASE_OPACITY_TRANSITION,
      }}
    />
  );
}

export const ForceRegisterStaffsActionButton = React.memo(_ForceRegisterStaffActionButton);

if (environment.__DEV__) {
  ForceRegisterStaffsActionButton.displayName = 'ForceRegisterStaffsActionButton';
}
