import { ActionMeta, GroupBase, OnChangeValue } from 'chakra-react-select';
import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { SelectBase } from './Select.base';
import { ISelectProps } from './Select.types';
import { IOption, IRef } from './types';

export function SelectFormWrapper<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  name,
  defaultValue,
  onChange,
  options = [],
  ...otherProps
}: ISelectProps<Option, IsMulti, Group>) {
  const { control } = useFormContext();
  const {
    field: { ref: controllableRef, onChange: onControllerChange, ...inputProps },
  } = useController({
    name,
    control,
    defaultValue,
  });

  /** Since react-hook-form pose a problem with the parsing of values as string */
  const getOptionFromValue = (value: string | IRef | IRef[]): Option | Option[] | null => {
    if (!value) return null;

    if (Array.isArray(value)) {
      return value.map(val => {
        return options.find(option => {
          const optionValue = (option as unknown as IOption).value;

          if (optionValue === null) {
            return null;
          } else if (typeof optionValue === 'string' && typeof val === 'string') {
            return optionValue === val;
          } else if (typeof optionValue !== 'string' && typeof val !== 'string') {
            return optionValue._id === (val as IRef)._id;
          }
        }) as Option;
      }) as Option[];
    } else {
      return options.find(option => {
        const optionValue = (option as unknown as IOption).value;

        if (optionValue === null) {
          return null;
        }
        if (typeof optionValue === 'string') {
          return optionValue === value;
        }
        return optionValue._id === (value as IRef)._id;
      }) as Option;
    }
  };

  const onControlledChange = useCallback(
    (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => {
      if (newValue === null) {
        onControllerChange(null);
      } else if (Array.isArray(newValue)) {
        onControllerChange(newValue.map(option => option.value));
      } else if (typeof newValue === 'object' && 'value' in newValue) {
        onControllerChange(newValue.value);
      }
      if (onChange) onChange(newValue, actionMeta);
    },
    [onControllerChange, onChange],
  );

  return (
    <SelectBase
      selectRef={controllableRef}
      onChange={onControlledChange}
      options={options}
      {...inputProps}
      {...otherProps}
      value={getOptionFromValue(inputProps.value)}
    />
  );
}
