import type { ITpIdSource } from '@epitech/panoramix-types';
import { endOfWeek, startOfWeek } from 'date-fns';
import type { StringifyOptions } from 'query-string';

import { ICalendarView } from '@/lib/types/calendar';

type LOGO_PATH = 'red' | 'blue';

interface IEnvironmentConfig {
  ENV: EnvironmentType;
  NODE: string;
  __DEV__: boolean;
  NAME: string;
  TEAMS_URI: string;
  LOGO_PATH: LOGO_PATH;
  QUERY_ARRAY_PARSER: StringifyOptions['arrayFormat'];
  PACKAGE_VERSION: string;
  FULLCALENDAR_LICENSE: string;
  services: {
    API_URI: string;
    SOURCE_URI: Record<ITpIdSource, string>;
  };
  sentry: {
    DSN: string;
    TRACES_SAMPLE_RATE: number;
    PROFILES_SAMPLE_RATE: number;
    NORMALIZE_DEPTH: number;
  };
  calendar: {
    DEFAULT_VIEW: ICalendarView;
    DEFAULT_ROOM_VIEW: ICalendarView;
    DEFAULT_START: Date;
    DEFAULT_END: Date;
  };
  office: {
    LOGOUT_REDIRECT_URI: string;
    REDIRECT_URI: string;
    AUTHORITY: string;
    CLIENT_ID: string;
  };
}

export type EnvironmentType = 'production' | 'development' | 'preproduction';

function isValidEnv(env: string): env is EnvironmentType {
  const envType = ['production', 'development', 'preproduction'];
  return envType.includes(env);
}

export const environment = {
  ENV: isValidEnv(import.meta.env.VITE_APP_ENV) ? import.meta.env.VITE_APP_ENV : 'production',
  NODE: import.meta.env.NODE_ENV!,
  __DEV__: import.meta.env.VITE_APP_ENV === 'development',
  NAME: import.meta.env.VITE_APP_NAME || 'Panoramix',
  TEAMS_URI: import.meta.env.VITE_APP_TEAMS_BASE_URI || 'https://teams.microsoft.com/l/chat/0/0',
  LOGO_PATH: (import.meta.env.VITE_APP_LOGO_PATH as LOGO_PATH) || 'blue',
  QUERY_ARRAY_PARSER: 'index',
  PACKAGE_VERSION: import.meta.env.VITE_APP_VERSION!,
  FULLCALENDAR_LICENSE: import.meta.env.VITE_APP_FULLCALENDAR_LICENSE!,
  calendar: {
    DEFAULT_START: startOfWeek(new Date(Date.now()), { weekStartsOn: 1 }),
    DEFAULT_END: endOfWeek(new Date(Date.now()), { weekStartsOn: 2 }),
    DEFAULT_VIEW: 'timeGridWeek',
    DEFAULT_ROOM_VIEW: 'resourceTimelineDay',
  },
  sentry: {
    DSN: import.meta.env.VITE_APP_SENTRY_DSN!,
    TRACES_SAMPLE_RATE: import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE
      ? parseFloat(import.meta.env.VITE_APP_SENTRY_TRACES_SAMPLE_RATE)
      : 1.0,
    PROFILES_SAMPLE_RATE: import.meta.env.VITE_APP_SENTRY_PROFILES_SAMPLE_RATE
      ? parseFloat(import.meta.env.VITE_APP_SENTRY_PROFILES_SAMPLE_RATE)
      : 1.0,
    NORMALIZE_DEPTH: import.meta.env.VITE_APP_SENTRY_NORMALIZE_DEPTH
      ? parseInt(import.meta.env.VITE_APP_SENTRY_NORMALIZE_DEPTH)
      : 10,
  },
  office: {
    AUTHORITY: import.meta.env.VITE_APP_OFFICE_AUTHORITY!,
    LOGOUT_REDIRECT_URI: import.meta.env.VITE_APP_OFFICE_POST_REDIRECT_URI!,
    REDIRECT_URI: import.meta.env.VITE_APP_OFFICE_REDIRECT_URI!,
    CLIENT_ID: import.meta.env.VITE_APP_OFFICE_CLIENT_ID!,
  },
  services: {
    SOURCE_URI: JSON.parse(import.meta.env.VITE_APP_SOURCE_URI!),
    API_URI: import.meta.env.VITE_APP_OPS_SERVICE_API!,
  },
} satisfies IEnvironmentConfig;
