import {
  Icon,
  IconButton,
  IconButtonProps,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiUserPlus } from 'react-icons/fi';
import type { IconType } from 'react-icons/lib';

import { PopoverPortal } from '@/components/ui-elements/PopoverPortal';

import {
  EventForceRegisterActionButtonContent,
  EventForceRegisterActionButtonContentProps,
} from './Content';

export interface EventForceRegisterActionButtonProps
  extends EventForceRegisterActionButtonContentProps {
  isDisabled?: boolean;
  hasPortal?: boolean;
  buttonProps?: Partial<IconButtonProps>;
  icon?: IconType;
}

function _EventForceRegisterActionButton({
  isDisabled,
  hasPortal = true,
  buttonProps = {},
  icon = FiUserPlus,
  ...forceRegisterProps
}: EventForceRegisterActionButtonProps): React.ReactElement | null {
  const { t } = useTranslation('components/events/force-register');

  const Wrapper = hasPortal ? PopoverPortal : React.Fragment;

  const stopPropagation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return (
    <Popover
      placement="auto"
      variant="noBorder"
      isLazy={true}
      boundary="scrollParent"
      returnFocusOnClose={false}
    >
      <PopoverTrigger>
        <IconButton
          icon={<Icon as={icon} />}
          colorScheme="blue"
          size="xs"
          onClick={stopPropagation}
          isDisabled={isDisabled}
          aria-label={t('action_force-register')}
          {...buttonProps}
        />
      </PopoverTrigger>
      <Wrapper>
        <PopoverContent p={4} boxShadow="lg" onClick={stopPropagation} minW="xs" w="fit-content">
          <PopoverArrow />
          <EventForceRegisterActionButtonContent {...forceRegisterProps} isDisabled={isDisabled} />
        </PopoverContent>
      </Wrapper>
    </Popover>
  );
}

export const EventForceRegisterActionButton = React.memo<EventForceRegisterActionButtonProps>(
  _EventForceRegisterActionButton,
);
