import { environment } from '@/config/environment';
import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchQuery } from './baseQueryWithReauth';

export const panoramixApi = createApi({
  reducerPath: 'panoramixApi',
  baseQuery: customFetchQuery(environment.services.API_URI),
  tagTypes: [
    'Event',
    'EventRef',
    'EventSample',
    'RoomRef',
    'Room',
    'EventHistory',
    'ActivityCityEvents',
    'RoomEvents',
    'Modules',
    'ModulesRef',
    'GroupingsRef',
    'ProjectGroupsRef',
    'FollowUpProjectGroup',
    'Cohorts',
    'CohortGroups',
    'CohortsRef',
    'UsersRef',
    'ProfileSettings',
  ],
  endpoints: () => ({}),
});
