import {
  type ILanguageSupported,
  LANGUAGE_SUPPORTED,
  type ProfileSettingsEntry,
} from '@epitech/panoramix-types';
import { SingleValue } from 'chakra-react-select';
import React, { useCallback, useMemo } from 'react';

import { Select } from '@/components/ui-elements/Select/Select';
import i18n from '@/lib/hooks/i18n';
import { useUpdateSettingsMutation } from '@/store/services/users';

import { IStringOption } from '../types';
import { CountryOption, CountryValue } from './CountryOption';
import { LOCALE_OPTIONS } from './LocalOptions';

interface IProps {
  profileSettings: ProfileSettingsEntry;
}

const _LanguageSelect: React.FC<IProps> = ({ profileSettings }) => {
  const [updateSettings] = useUpdateSettingsMutation();

  const changeLanguage = useCallback(
    async (option: SingleValue<IStringOption>) => {
      const newLanguage = option?.value.toLowerCase() === 'gb' ? 'en' : option?.value.toLowerCase();

      if (
        !newLanguage ||
        newLanguage === i18n.language ||
        !LANGUAGE_SUPPORTED.includes(newLanguage as ILanguageSupported)
      )
        return;

      if (
        LANGUAGE_SUPPORTED.includes(newLanguage as ILanguageSupported) &&
        newLanguage !== profileSettings.language
      ) {
        updateSettings({ language: newLanguage as ILanguageSupported });
      }
    },
    [profileSettings.language, updateSettings],
  );

  const currentLanguage = useMemo(() => {
    const language =
      LOCALE_OPTIONS.find(option => {
        if (profileSettings.language === 'en') {
          return option.value === 'gb';
        }
        return option.value === profileSettings.language;
      }) ?? LOCALE_OPTIONS[0];
    return language;
  }, [profileSettings.language]);

  return (
    <Select
      defaultValue={currentLanguage}
      components={{ Option: CountryOption, SingleValue: CountryValue }}
      options={LOCALE_OPTIONS}
      onChange={changeLanguage}
      name="language"
      variant="ghost"
      chakraStyles={{
        valueContainer: provided => ({
          ...provided,
          px: 2,
        }),
      }}
    />
  );
};

export const LanguageSelect = React.memo(_LanguageSelect);
