import { subject } from '@casl/ability';
import { ColorMode } from '@chakra-ui/react';
import type { EventRef, ModuleRef, ProjectGroupRef, UserRef } from '@epitech/panoramix-types';
import {
  type EventEntry,
  EventRegisteredSample,
  type EventSample,
  type EventSlotEntry,
  type EventStudentEntry,
  getSlotInput,
  HistoryEntry,
  type IEventFormInput,
  StoredEventEntry,
} from '@epitech/panoramix-types';

import { DEFAULT_SLOT_PARAMS } from '@/config/constants';
import { ModuleColors } from '@/store/services/settings.slice';

import type { DateObject, WithAllDay, WithRawDates } from '../types/events';
import { sanitizeDatePayload } from './dates.helpers';

export function isUserOrGroupAlreadyRegisteredToEvent(
  toRegister: UserRef | ProjectGroupRef,
  event: EventEntry | EventStudentEntry,
) {
  const isGroupMode = event?.registrationType === 'group';

  switch (event.type) {
    case 'course':
      return isGroupMode
        ? event.registeredGroups.some(group => group._id === toRegister._id)
        : event.registeredUsers.some(user => user._id === toRegister._id);
    case 'appointment_multiple':
      return isGroupMode
        ? event.slots.some(
            slot =>
              slot.registeredGroups?.some(
                registeredGroup => registeredGroup._id === toRegister._id,
              ),
          )
        : event.slots.some(slot => slot.registeredUsers?.some(user => user._id === toRegister._id));
    case 'appointment':
      return isGroupMode
        ? event.slots.some(slot => slot.registeredGroup?._id === toRegister._id)
        : event.slots.some(slot => slot.registeredUser?._id === toRegister._id);
    default:
      return false;
  }
}

export const sanitizeRegisteredSamplePayload = (
  registeredSample: WithRawDates<EventRegisteredSample>,
): EventRegisteredSample => {
  return sanitizeDatePayload<EventRegisteredSample>(registeredSample);
};

export const sanitizeEventPayload = (
  event?: WithRawDates<StoredEventEntry>,
): StoredEventEntry | null => {
  if (!event) {
    return null;
  }

  const sanitizedPayload = sanitizeDatePayload<StoredEventEntry>(event);

  return subject('event', sanitizedPayload);
};

export const setAllDay = <K extends DateObject>(dateObject: K, startCal?: Date, endCal?: Date) => {
  const { start, end } = dateObject;
  let allDay = false;

  if (
    start.getHours() === 0 &&
    start.getMinutes() === 0 &&
    end.getHours() === 0 &&
    end.getMinutes() === 0 &&
    end.getDay() > start.getDay()
  )
    allDay = true;

  if (startCal !== undefined && endCal !== undefined && start <= startCal && endCal <= end)
    allDay = true;

  return { ...dateObject, start, end, allDay };
};

export function splitSlotsByConcurrency<T extends EventSlotEntry>(
  slots: T[],
  nth: number,
): (T & { oldIndex?: number })[][] {
  const result: (T & { oldIndex?: number })[][] = Array(nth)
    .fill(null)
    .map(() => []);

  slots.forEach((item, index) => {
    if (item.type === 'break') {
      for (let i = 0; i < nth; i++) {
        result[i].push(item);
      }
      return;
    }
    result[index % nth].push({ ...item, oldIndex: index });
  });

  return result;
}

// TODO: why are we sanitizing events with `queryStart` and `queryEnd` ?
export const getAllDaySetter = <T extends EventRef>(rawStartCal: string, rawEndCal: string) => {
  const startCal = new Date(rawStartCal);
  const endCal = new Date(rawEndCal);

  return (event: T): WithAllDay<T> => {
    const { start, end } = event;
    let allDay = false;

    if (
      start.getHours() === 0 &&
      start.getMinutes() === 0 &&
      end.getHours() === 0 &&
      end.getMinutes() === 0 &&
      end.getDay() > start.getDay()
    ) {
      allDay = true;
    }

    if (startCal !== undefined && endCal !== undefined && start <= startCal && endCal <= end) {
      allDay = true;
    }

    return { ...event, allDay };
  };
};

export function getEventPrefix(moduleRef?: ModuleRef | null) {
  return moduleRef
    ? `[${moduleRef?.code.split('_')[0]}]${
        moduleRef.activityRef ? ` [${moduleRef.activityRef?.name || ''}]` : ''
      }`
    : null;
}

export const sanitizeFormEvent = (event?: EventEntry): IEventFormInput | null => {
  if (!event) {
    return null;
  }

  return event.type === 'course'
    ? event
    : {
        ...event,
        slots:
          event.slots && event.slots.length
            ? getSlotInput(event.slots)
            : {
                ...DEFAULT_SLOT_PARAMS,
                type: event.type === 'appointment' ? 'slot' : 'slot_multiple',
              },
      };
};

export function getModuleColorMapper<K extends EventSample>(
  moduleColors: ModuleColors,
  colorMode: ColorMode,
) {
  return (event: K) => {
    if (event.moduleRef && moduleColors[event.moduleRef._id]) {
      return {
        className: event.type,
        backgroundColor: moduleColors[event.moduleRef._id][colorMode],
      };
    }
    return { className: event.type, color: colorMode === 'light' ? 'lightpink' : 'purple' };
  };
}

export function getHistoryLogCreator(history: HistoryEntry[]) {
  const createEntry = history.find(history => history.action === 'create');
  if (!createEntry) {
    return null;
  }

  return { userRef: createEntry!.userRef, date: createEntry!.date };
}
