import { StoredEventEntry } from '@epitech/panoramix-types';
import { PartialBy } from '@epitech/panoramix-types';
import React from 'react';

import { environment } from '@/config/environment';

import {
  ForceRegisterStaffsAppointment,
  type ForceRegisterStaffsAppointmentProps,
} from './Appointment';
import { ForceRegisterStaffsCourse, type ForceRegisterStaffsCourseProps } from './Course';

interface ForceRegisterStaffsProps
  extends Omit<ForceRegisterStaffsCourseProps, 'event'>,
    PartialBy<Omit<ForceRegisterStaffsAppointmentProps, 'event'>, 'slotIdx'> {
  event: StoredEventEntry;
}

function _ForceRegisterStaffs(props: ForceRegisterStaffsProps) {
  const { event, slotIdx } = props;

  switch (event.type) {
    case 'course':
      return <ForceRegisterStaffsCourse {...props} event={event} />;
    case 'appointment':
    case 'appointment_multiple':
      if (slotIdx === undefined || isNaN(slotIdx)) {
        throw new Error('slotIdx is required for appointment');
      }
      return <ForceRegisterStaffsAppointment {...props} slotIdx={slotIdx} event={event} />;
    default:
      return null;
  }
}

export const ForceRegisterStaffs = React.memo(_ForceRegisterStaffs);

if (environment.__DEV__) {
  ForceRegisterStaffs.displayName = 'ForceRegisterStaffs';
}
