import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { RoomBase } from '@epitech/panoramix-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

function _EventFormSeats(): React.ReactElement {
  const { register, setValue, watch, getValues } = useFormContext();
  const { t } = useTranslation('components/event-form');
  const [seats, seatActive] = watch(['seats', 'seatActive']);
  const [active, setActive] = useState(Boolean(seats));

  useEffect(() => {
    if (seatActive === undefined) {
      setValue('seatActive', false);
    } else if (seats > 0) {
      setValue('seatActive', true);
    }
  }, [seatActive, seats, setValue]);

  const onToggle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.checked) {
        setValue('seats', null);
        setValue('seatActive', false);
      } else {
        setValue(
          'seats',
          (getValues('roomsRef') ?? []).reduce(
            (acc: number, value: RoomBase) => acc + value.seats,
            0,
          ),
        );
        setValue('seatActive', true);
      }
      setActive(e.target.checked);
    },
    [setActive, setValue, getValues],
  );

  return (
    <FormControl flex={1}>
      <HStack alignItems="center" mb="8px">
        <FormLabel mb={0} mr="auto">
          {t('seats_label')}
        </FormLabel>
        <Checkbox
          size="md"
          onChange={onToggle}
          checked={seatActive}
          defaultChecked={seats > 0}
          borderColor="gray.400"
        />
      </HStack>
      <NumberInput
        mr={1}
        variant="white"
        isDisabled={!active}
        min={0}
        value={seats || 0}
        onChange={value => setValue('seats', parseInt(value))}
      >
        <NumberInputField {...register('seats')} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  );
}

export const EventFormSeats = React.memo(_EventFormSeats);
