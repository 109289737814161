import { Tag } from '@chakra-ui/react';
import type { CohortGroup, CohortRef } from '@epitech/panoramix-types';
import React from 'react';

import { environment } from '@/config/environment';

interface EventDetailsCohortGroupProps {
  cohortGroup: CohortGroup;
}

function _EventDetailsCohortGroup({ cohortGroup }: EventDetailsCohortGroupProps) {
  const cohorts: CohortRef[] = Object.values(cohortGroup).filter(Boolean);

  return (
    <Tag size="md" fontFamily="mono" colorScheme="teal">
      {cohorts.map(cohort => cohort.name).join(' / ')}
    </Tag>
  );
}

export const EventDetailsCohortGroup = React.memo(_EventDetailsCohortGroup);

if (environment.__DEV__) {
  EventDetailsCohortGroup.displayName = 'EventDetailsCohortGroup';
}
