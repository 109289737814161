import { HStack, Icon, IconButton, type StackProps } from '@chakra-ui/react';
import { ProjectGroupRef } from '@epitech/panoramix-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

import { RegisteredItemProps } from '@/components/events/registered/types';
import { ConfirmPopover } from '@/components/ui-elements/ConfirmPopover';
import { environment } from '@/config/environment';
import { BASE_OPACITY_TRANSITION } from '@/config/theme/components/others/UserItem';
import { useEventUnregistration } from '@/lib/hooks/useEventUnregistration';

import { GroupItem } from '../../groups';

interface RegisteredGroupItemProps extends RegisteredItemProps {
  group: ProjectGroupRef;
}

function _RegisteredGroupItem({
  group: projectGroupRef,
  hasPortal = true,
  canUnregister = false,
  canForceUnregister = false,
  unregisterBtnOnHover: _unregisterBtnOnHover,
  eventId,
  slotIdx,
  ...stackProps
}: RegisteredGroupItemProps & StackProps) {
  const { t } = useTranslation('components/events');
  const [unregisterFromEvent, { isLoading: isUnregisterLoading }] = useEventUnregistration({
    canForceRegister: canForceUnregister,
    eventId,
    slotIdx,
    toRegister: projectGroupRef,
    type: 'projectGroups',
  });

  const unregisterBtnOnHover =
    _unregisterBtnOnHover !== undefined
      ? _unregisterBtnOnHover
      : !canForceUnregister && !canUnregister;

  const btnProps = useMemo(
    () =>
      unregisterBtnOnHover
        ? {
            opacity: 0,
            transition: BASE_OPACITY_TRANSITION,
            _groupHover: {
              opacity: 1,
            },
          }
        : {},
    [unregisterBtnOnHover],
  );

  const deleteButton = useMemo(
    () =>
      canForceUnregister || canUnregister ? (
        <ConfirmPopover
          onConfirm={unregisterFromEvent}
          isLoading={isUnregisterLoading}
          hasPortal={hasPortal}
          confirmLabel={t('unregister_confirm')}
          confirmButtonLabel={t('unregister_btn_label')}
          resourceName={projectGroupRef.name}
          renderTrigger={() => (
            <IconButton
              icon={<Icon as={FiX} />}
              colorScheme="red"
              variant="ghost"
              aria-label="unregister"
              size="xs"
              ml="auto"
              {...btnProps}
            />
          )}
        />
      ) : null,
    [
      isUnregisterLoading,
      projectGroupRef.name,
      canUnregister,
      canForceUnregister,
      unregisterFromEvent,
      t,
      btnProps,
      hasPortal,
    ],
  );

  return (
    <HStack
      display="flex"
      flexDir="row"
      alignItems="center"
      w="full"
      role="group"
      gap={2}
      {...stackProps}
    >
      <GroupItem
        value={projectGroupRef}
        popoverBtnOnHover={unregisterBtnOnHover}
        triggerSize="xs"
      />
      {deleteButton}
    </HStack>
  );
}

export const RegisteredGroupItem = React.memo(_RegisteredGroupItem);

if (environment.__DEV__) {
  RegisteredGroupItem.displayName = 'RegisteredGroupItem';
}
