import { List, Theme } from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { RoomEvents } from "@epitech/panoramix-types";
import React from "react";

import { RoomStatusProps } from "../RoomStatusContainer";
import { RoomListItem } from "./ListItem";

function _RoomList({rooms}: RoomStatusProps): React.ReactElement {
  const theme = useTheme() as Theme;

  return (
    <List 
      display="grid"
      gridTemplateColumns={{sm: '1fr', md: '1fr', xl: '1fr 1fr'}}
      sx={{
        "& > *:nth-of-type(4n+3), & > *:nth-of-type(4n+4)": {
          bgColor: "cardBgColor",
        },
        [`@media screen and (max-width: ${theme.breakpoints.xl})`]: {
          "& > *:nth-of-type(4n+3), & > *:nth-of-type(4n+4)": {
            bgColor: "unset",
          },
          "& > *:nth-of-type(2n)": {
            bgColor: "cardBgColor"
          },
        }
      }}
      gridGap={1}
    >
      {
        rooms?.map((el: RoomEvents) => (
          <RoomListItem 
            key={el._id}
            name={el.name}
            events={el.events}
            nowTimeStamp={Date.now()}
          />
          
        ))
      }
    </List>
  )
}

export const RoomList = React.memo<RoomStatusProps>(_RoomList);
