import { ButtonProps, Icon, IconButton, useToast } from '@chakra-ui/react';
import { EventSlotEntry } from '@epitech/panoramix-types';
import { RoomRef } from '@epitech/panoramix-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

import { EventForceRegisterActionButton } from '@/components/events/force-register';
import { ConfirmPopover } from '@/components/ui-elements/ConfirmPopover';
import { environment } from '@/config/environment';
import { useUnregisterRoomToSlotMutation } from '@/store/services/events';

interface IRoomActionButtonProps extends Omit<ButtonProps, 'slot'> {
  eventId: string;
  slot: EventSlotEntry & { roomRef?: RoomRef };
  slotIdx: number;
  isSlotFull: boolean;
}

function _RoomActionButton({
  eventId,
  slot,
  slotIdx,
  isSlotFull,
  ...buttonProps
}: IRoomActionButtonProps) {
  const { t } = useTranslation('components/events');
  const toast = useToast();

  const [unregisterRoomFromSlot, { isLoading }] = useUnregisterRoomToSlotMutation();

  const onRoomUnregistration = useCallback(async () => {
    await unregisterRoomFromSlot({
      eventId,
      slotIdx,
    }).unwrap();

    toast({
      description: t('success_room_desinscription'),
      status: 'success',
      position: 'top',
    });
  }, [eventId, unregisterRoomFromSlot, t, toast, slotIdx]);

  if (isSlotFull) {
    return (
      <ConfirmPopover
        onConfirm={onRoomUnregistration}
        isLoading={isLoading}
        resourceName={slot?.roomRef?.name}
        confirmLabel={t('unregister_room_confirm')}
        confirmButtonLabel={t('unregister_btn_label')}
        renderTrigger={() => (
          <IconButton
            colorScheme="red"
            icon={<Icon as={FiX} />}
            aria-label={t('desinscription')}
            size="xs"
            {...buttonProps}
          />
        )}
      />
    );
  }
  return (
    <EventForceRegisterActionButton
      userType="room"
      eventId={eventId}
      slot={slot}
      slotIdx={slotIdx}
      buttonProps={buttonProps}
    />
  );
}

export const RoomActionButton = React.memo(_RoomActionButton);

if (environment.__DEV__) {
  RoomActionButton.displayName = 'RoomActionButton';
}
