import type { EventAppointmentMultipleEntry } from '@epitech/panoramix-types';
import type { Stored } from '@epitech/panoramix-types';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ForceRegisterTabListWrapper } from '@/components/events/force-register/shared/TabListWrapper';
import { GroupItem, GroupOption, IProjectGroupOption, useGroupSearch } from '@/components/groups';
import { SearchMenuMulti } from '@/components/ui-elements/SearchMenu/Multi';
import type { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { environment } from '@/config/environment';
import { useForceRegisterToEventMutation } from '@/store/services/events';

export type ForceRegisterGroupsAppointmentMultipleProps = {
  event: Stored<EventAppointmentMultipleEntry>;
  slotIdx: number;
  onClose: () => void;
};

function _ForceRegisterGroupsAppointmentMultiple({
  event,
  slotIdx,
  onClose,
}: ForceRegisterGroupsAppointmentMultipleProps) {
  const { t } = useTranslation('components/events');
  const slot = useMemo(() => event.slots[slotIdx], [event.slots, slotIdx]);
  const isAddDisabled = slot.registeredGroups.length >= event.maxRegistrationsBySlot;
  const nbOfGroupsLeft = event.maxRegistrationsBySlot - slot.registeredGroups.length;
  const [forceRegisterToEvent, { isLoading }] = useForceRegisterToEventMutation();

  const searchProjectGroups = useGroupSearch(event.moduleRef);

  const onSubmitHandler = useCallback(
    (selectedGroups: IProjectGroupOption[]) => {
      forceRegisterToEvent({
        eventId: event._id!,
        toRegisters: selectedGroups.map(group => group.value),
        type: 'projectGroups',
        slotIdx,
      });
      onClose();
    },
    [onClose, event._id, forceRegisterToEvent, slotIdx],
  );

  const filterOption = (option: FilterOptionOption<IProjectGroupOption>) => {
    return !event.slots.some(slot =>
      slot.registeredGroups.some(registeredGroup => registeredGroup._id === option.data.value._id),
    );
  };

  const onNoOptions = useCallback(() => t('empty_user-search'), [t]);

  return (
    <ForceRegisterTabListWrapper
      eventId={event._id}
      slotIdx={slotIdx}
      type="projectGroups"
      registeredItems={slot.registeredGroups}
      isAddDisabled={isAddDisabled}
    >
      <SearchMenuMulti<IProjectGroupOption>
        name="forceRegisterAppointmentMultipleGroups"
        maxSelectedItems={nbOfGroupsLeft}
        loadOptions={searchProjectGroups}
        onSubmit={onSubmitHandler}
        components={{ Option: GroupOption, Value: GroupItem }}
        isLoading={isLoading}
        noOptionsMessage={onNoOptions}
        filterOption={filterOption}
        infiniteScroll={true}
      />
    </ForceRegisterTabListWrapper>
  );
}

export const ForceRegisterGroupsAppointmentMultiple = React.memo(
  _ForceRegisterGroupsAppointmentMultiple,
);

if (environment.__DEV__) {
  ForceRegisterGroupsAppointmentMultiple.displayName = 'ForceRegisterGroupsAppointmentMultiple';
}
