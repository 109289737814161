import { Flex } from '@chakra-ui/react';
import { PartialRoomEvent, RoomEvents } from '@epitech/panoramix-types';
import { addHours } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSearchEventsWithRoomsByCityIdQuery } from '@/store/services/events';

import { PageIndicator } from '../../ui-elements/Pagination/PageIndicator';
import { RoomGrid } from './grid/Grid';
import { RoomList } from './list/List';

export type RoomStatusViewType = 'grid' | 'list' | 'tv';

interface RoomStatusContainerProps {
  roomsFilter: string[];
  view: RoomStatusViewType;
  maxElems: number;
  cityId: string;
  start: string;
  end: string;
}

export interface RoomStatusProps {
  rooms: RoomEvents[];
  autoScroll?: boolean;
  autoScrollCallback?: (arg: void) => void;
  autoScrollTime?: number;
}

export interface RoomStatusItemProps {
  name?: string;
  events: PartialRoomEvent[];
  nowTimeStamp: number;
}

function _RoomStatusContainer({
  roomsFilter,
  view,
  cityId,
  start,
  end,
  maxElems,
}: RoomStatusContainerProps): React.ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = +(searchParams.get('page') || '0');

  const { data: fetchedRooms } = useSearchEventsWithRoomsByCityIdQuery(
    {
      rooms: roomsFilter,
      cityId: cityId,
      start: start,
      end: end,
      limit: maxElems,
      offset: pageParam * maxElems,
    },
    { 
      refetchOnMountOrArgChange: true,
      pollingInterval: 10000,
    },
  );

  const [rooms, pagination] = useMemo(() => {
    return [fetchedRooms?.data, fetchedRooms?.pagination];
  }, [fetchedRooms]);

  const maxPages: number = useMemo(() => {
    return Math.ceil((pagination?.count || 0) / maxElems);
  }, [pagination?.count, maxElems]);

  const setStartDate = useCallback(() => {
    const now = new Date();
    const newEndDate = addHours(now, 1);
    setSearchParams(prev => {
      prev.set('start', now.toISOString());
      return prev;
    });
    setSearchParams(prev => {
      prev.set('end', newEndDate.toISOString());
      return prev;
    });
  }, [setSearchParams]);

  const onPrevClick = useCallback(() => {
    if (pageParam <= 0) {
      setSearchParams(prev => {
        prev.set('page', String(maxPages - 1));
        return prev;
      });
      setStartDate()
      return;
    }
    setSearchParams(prev => {
      prev.set('page', String(pageParam - 1));
      return prev;
    });
  }, [pageParam, maxPages, setStartDate, setSearchParams]);

  const onNextClick = useCallback(() => {
    if (pageParam >= maxPages - 1) {
      setSearchParams(prev => {
        prev.set('page', String(0));
        return prev;
      });
      setStartDate()
      return;
    }
    setSearchParams(prev => {
      prev.set('page', String(pageParam + 1));
      return prev;
    });
  }, [pageParam, maxPages, setStartDate,  setSearchParams]);

  const activeView = useMemo(() => {
    switch (view) {
      case 'grid':
        return <RoomGrid rooms={rooms || []} />;
      case 'list':
        return <RoomList rooms={rooms || []} />;
      case 'tv':
      return (
        <RoomGrid
          rooms={rooms || []}
          autoScroll={true}
          autoScrollCallback={onNextClick}
          autoScrollTime={15000}
        />
      );
      default:
        return <RoomGrid rooms={rooms || []} />;
    }
  }, [rooms, onNextClick, view]);

  return (
    <Flex flexDirection="column">
      {activeView}
      {(pagination?.count || 0) > maxElems && (
        <PageIndicator
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          page={pageParam}
          maxPages={maxPages}
        />
      )}
    </Flex>
  );
}

export const RoomStatusContainer = React.memo(_RoomStatusContainer);
