import { HStack, Tag, Text } from '@chakra-ui/react';
import { chakraComponents, GroupBase, SelectComponentsConfig } from 'chakra-react-select';
import he from 'he';
import React from 'react';

import type { IModuleOption } from './helpers';

export const ModuleValue: SelectComponentsConfig<
  IModuleOption,
  false,
  GroupBase<IModuleOption>
>['SingleValue'] = React.memo(({ children, ...props }) => (
  <chakraComponents.SingleValue {...props}>
    <HStack spacing={2} alignItems="center" w="full" justifyContent="space-between">
      <Text flex="1" overflow="hidden" textOverflow="ellipsis">
        {children}
      </Text>
      <Tag mr={1} variant="moduleCode" width="fit-content" flexShrink={0}>
        {he.decode(props.data.value.code)}
      </Tag>
    </HStack>
  </chakraComponents.SingleValue>
));

export const ModuleMultiValue: SelectComponentsConfig<
  IModuleOption,
  true,
  GroupBase<IModuleOption>
>['MultiValue'] = React.memo(props => (
  <chakraComponents.MultiValue {...props}>{props.data.value.code}</chakraComponents.MultiValue>
));
