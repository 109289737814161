import { chakraComponents, SingleValueProps } from 'chakra-react-select';
import React from 'react';

import { GroupItem } from '../GroupItem';
import type { IProjectGroupOption } from './helpers';

export function GroupValue<IsMulti extends boolean = false>(
  props: React.PropsWithChildren<SingleValueProps<IProjectGroupOption, IsMulti>>,
) {
  const { data } = props;

  return (
    <chakraComponents.SingleValue {...props}>
      <GroupItem w="full" value={data.value} triggerVariant="solid" />
    </chakraComponents.SingleValue>
  );
}
