import {
  Alert,
  FormControl,
  FormLabel,
  Heading,
  NumberInputProps,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import {
  computeSlots,
  EventSlotInput,
  MAXIMUM_SLOTS_VALUES,
} from '@epitech/panoramix-types';
import { isEqual, setMilliseconds } from 'date-fns';
import format from 'date-fns/format';
import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatePicker } from '@/components/ui-elements/DatePicker';
import { FormNumberInput } from '@/components/ui-elements/FormNumberInput';
import { environment } from '@/config/environment';

import { IEventFormRawData } from '../form.types';
import { EventFormSlotsBreaks } from './Breaks';
import { filterUnsetBreaks } from './slots.helpers';
import { EventFormSlotProps } from './slots.types';

const INPUT_SIZE: NumberInputProps['size'] = 'sm';

function _EventFormSlots({ eventStart, eventEnd }: EventFormSlotProps) {
  const { t } = useTranslation('layouts/calendar/eventForm/slots/EventFormSlots');
  const {
    control,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useFormContext<IEventFormRawData>();

  const loadedFormat = t('date_format');
  const dateFormat = loadedFormat === 'date_format' ? 'hh:mm a' : loadedFormat;

  const [endDateOverflow, type, duration, occurence, slotNumber, breakDuration] = watch([
    'endDateOverflow',
    'type',
    'slots.duration',
    'slots.occurence',
    'slots.slotNumber',
    'slots.breakDuration',
  ]);

  const slotType = type === 'appointment' ? 'slot' : 'slot_multiple';

  const breaks = useWatch({
    name: 'slots.breaks',
    control,
  });

  const [compSlots, newEndDate] = useMemo(() => {
    if (
      !eventEnd ||
      !eventStart ||
      (slotNumber === undefined && eventEnd.getTime() < eventStart.getTime()) ||
      typeof occurence === 'string' ||
      typeof duration === 'string' ||
      typeof breakDuration === 'string' ||
      typeof slotNumber === 'string'
    ) {
      return [[], new Date(), false];
    }
    const newSlots: EventSlotInput = {
      ...{ duration, occurence, slotNumber, breakDuration, type: slotType },
      breaks: filterUnsetBreaks(breaks),
    };
    return computeSlots(eventStart, !slotNumber ? eventEnd : undefined, newSlots);
  }, [duration, occurence, slotNumber, breakDuration, eventEnd, breaks, eventStart, slotType]);

  useEffect(() => {
    setValue('slots.type', slotType);
  }, [slotType, setValue]);

  useEffect(() => {
    if (
      eventEnd &&
      newEndDate &&
      !isEqual(setMilliseconds(newEndDate, 0), setMilliseconds(eventEnd, 0))
    ) {
      setValue('endDateOverflow', isNaN(newEndDate.getTime()) ? eventEnd : newEndDate);
    } else {
      setValue('endDateOverflow', undefined);
    }
    if (slotNumber === undefined) {
      setValue('slots.slotNumber', compSlots.length);
    }
  }, [setValue, setError, compSlots, newEndDate, eventEnd, slotNumber, errors]);

  const computedEnd = useMemo(
    () =>
      endDateOverflow && eventEnd && endDateOverflow.getTime() > eventEnd.getTime()
        ? endDateOverflow
        : eventEnd,
    [endDateOverflow, eventEnd],
  );

  return (
    <VStack spacing={2} align="start">
      <Heading size="sm">{t('slots')}</Heading>
      {endDateOverflow !== undefined && endDateOverflow !== null && (
        <Alert status="warning" borderRadius="sm" fontSize={INPUT_SIZE}>
          {t('warning_end')}&nbsp;({format(endDateOverflow, dateFormat)})
        </Alert>
      )}
      <SimpleGrid minChildWidth={32} gap={2} w="full">
        <FormControl label={t('slots_number')}>
          <FormLabel whiteSpace="nowrap" fontSize={INPUT_SIZE}>
            {t('slots_number')}
          </FormLabel>
          <FormNumberInput
            size={INPUT_SIZE}
            variant="white"
            name="slots.slotNumber"
            min={0}
            max={MAXIMUM_SLOTS_VALUES.slotNumber}
            isDisabled={!eventStart || !eventEnd}
            control={control}
          />
          <DatePicker name="endDateOverflow" display={'none'} defaultValue={eventEnd} />
        </FormControl>
        <FormControl label={t('duration')}>
          <FormLabel fontSize={INPUT_SIZE}>{t('duration')}</FormLabel>
          <FormNumberInput
            size={INPUT_SIZE}
            name="slots.duration"
            min={1}
            max={MAXIMUM_SLOTS_VALUES.duration}
            variant="white"
            isDisabled={!eventStart || !eventEnd}
            control={control}
          />
        </FormControl>
        <FormControl label={t('number')}>
          <FormLabel fontSize={INPUT_SIZE}>{t('number')}</FormLabel>
          <FormNumberInput
            size={INPUT_SIZE}
            name="slots.occurence"
            min={1}
            max={MAXIMUM_SLOTS_VALUES.occurence}
            variant="white"
            isDisabled={!eventStart || !eventEnd}
            control={control}
          />
        </FormControl>
        <FormControl label={t('break')}>
          <FormLabel fontSize={INPUT_SIZE}>{t('break')}</FormLabel>
          <FormNumberInput
            size={INPUT_SIZE}
            name="slots.breakDuration"
            max={MAXIMUM_SLOTS_VALUES.breakDuration}
            min={0}
            variant="white"
            keepWithinRange={true}
            isDisabled={!eventStart || !eventEnd}
            control={control}
          />
        </FormControl>
        {type === 'appointment_multiple' && (
          <FormControl label={t('max_registrations')}>
            <FormLabel fontSize={INPUT_SIZE} whiteSpace="nowrap">
              {t('max_registrations')}
            </FormLabel>
            <FormNumberInput
              size={INPUT_SIZE}
              defaultValue={1}
              name="maxRegistrationsBySlot"
              min={1}
              variant="white"
              keepWithinRange={true}
              isDisabled={!eventStart || !eventEnd}
              control={control}
            />
          </FormControl>
        )}
      </SimpleGrid>
      <EventFormSlotsBreaks eventStart={eventStart} eventEnd={computedEnd} errors={errors} />
    </VStack>
  );
}

export const EventFormSlots = React.memo<EventFormSlotProps>(_EventFormSlots);

if (environment.__DEV__) {
  EventFormSlots.displayName = 'EventFormSlots';
}
