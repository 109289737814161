import {
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackProps,
  Text,
} from '@chakra-ui/react';
import type { ProjectGroupEntry } from '@epitech/panoramix-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiUsers } from 'react-icons/fi';

import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';
import { BASE_OPACITY_TRANSITION } from '@/config/theme';

import { PopoverPortal } from '../ui-elements/PopoverPortal';

type GroupItemProps = {
  value: Pick<ProjectGroupEntry, 'name' | 'usersRef'>;
  triggerSize?: IconButtonProps['size'];
  triggerVariant?: IconButtonProps['variant'];
  popoverBtnOnHover?: boolean;
  hasPortal?: boolean;
} & StackProps;

const _GroupItem = React.forwardRef(function _GroupItem(
  {
    value,
    triggerSize = 'xs',
    triggerVariant = 'ghost',
    popoverBtnOnHover = true,
    hasPortal = true,
    ...stackProps
  }: GroupItemProps,
  ref,
) {
  const { t } = useTranslation('components/groups');
  const { name, usersRef } = value;
  const Wrapper = hasPortal ? PopoverPortal : React.Fragment;

  const iconBtnStyles = useMemo(
    () =>
      popoverBtnOnHover
        ? {
            opacity: 0,
            transition: BASE_OPACITY_TRANSITION,
            _groupHover: {
              opacity: 1,
            },
          }
        : {},
    [popoverBtnOnHover],
  );

  return (
    <HStack
      flex={1}
      justifyContent="space-between"
      gap={2}
      alignItems="center"
      role="group"
      fontSize="sm"
      position="relative"
      {...stackProps}
      ref={ref}
    >
      <Text mr="auto" textOverflow="ellipsis" flexShrink={1} minW="1ch" overflow="hidden">
        {name}
      </Text>
      {usersRef.length > 0 && (
        <Popover trigger="hover" placement="bottom" isLazy={true}>
          <PopoverTrigger>
            <IconButton
              size={triggerSize}
              variant={triggerVariant}
              icon={<Icon as={FiUsers} />}
              colorScheme="gray"
              aria-label={t('show')}
              flexShrink={0}
              {...iconBtnStyles}
            />
          </PopoverTrigger>
          <Wrapper>
            <PopoverContent shadow="lg" w="full" h="full" overflowY="auto">
              <PopoverArrow />
              <PopoverBody as={List} variant="bordered" maxH={400}>
                {usersRef.map(userRef => (
                  <UserItem as={ListItem} value={userRef} key={userRef._id} px={2} />
                ))}
              </PopoverBody>
            </PopoverContent>
          </Wrapper>
        </Popover>
      )}
    </HStack>
  );
});

export const GroupItem = React.memo<GroupItemProps>(_GroupItem);

if (environment.__DEV__) {
  GroupItem.displayName = 'GroupItem';
}
