import { useAbility } from '@casl/react';
import type { ProjectGroupRef, UserRef } from '@epitech/panoramix-types';
import {
  checkCohortGroupsFromAllowed,
  type EventServiceAbility,
  type StoredEventEntry,
} from '@epitech/panoramix-types';
import isPast from 'date-fns/isPast';
import { type PropsWithChildren, useEffect, useMemo } from 'react';

import { environment } from '@/config/environment';
import { AbilityContext } from '@/config/providers';
import { isUserOrGroupAlreadyRegisteredToEvent } from '@/lib/helpers/events.helpers';
import { useAuth } from '@/lib/hooks/useAuth';
import { useLazyGetProjectGroupQuery } from '@/store/services/modules';
import EventDetailsContext from './DetailsContext';

interface IEventDetailsProviderProps {
  event: StoredEventEntry;
  toRegister?: UserRef | ProjectGroupRef;
}

export function EventDetailsProvider({
  event,
  children,
}: PropsWithChildren<IEventDetailsProviderProps>) {
  const { user } = useAuth();
  const ability = useAbility(AbilityContext);
  const [fetchProjectGroup, { data: projectGroup }] = useLazyGetProjectGroupQuery();

  const isGroupMode = event?.registrationType === 'group';

  /** Effects hooks */
  useEffect(() => {
    if (event?.moduleRef && isGroupMode && !projectGroup) {
      fetchProjectGroup({
        groupingIds: event.moduleRef.groupingsRef?.map(grouping => grouping._id),
        moduleId: event.moduleRef._id,
        userId: user._id,
      });
    }
  }, [isGroupMode, event?.moduleRef, fetchProjectGroup, user._id, projectGroup]);

  const toRegister = useMemo(
    () => (isGroupMode ? projectGroup : user),
    [user, projectGroup, isGroupMode],
  );

  const isAlreadyRegistered = useMemo(
    () => (toRegister ? isUserOrGroupAlreadyRegisteredToEvent(toRegister, event) : false),
    [event, toRegister],
  );

  /** We separate this calls to not include `ability` and `event` in the `value` memo dependency array for performance reasons */
  const canUpdate = useMemo(
    () =>
      ability.can('update', event) &&
      checkCohortGroupsFromAllowed(
        'update',
        ability as EventServiceAbility,
        event.cohortGroups,
        true,
      ),
    [event, ability],
  );

  const canDelete = useMemo(
    () =>
      ability.can('delete', event) &&
      checkCohortGroupsFromAllowed(
        'delete',
        ability as EventServiceAbility,
        event.cohortGroups,
        true,
      ),
    [event, ability],
  );
  const canViewHistory = useMemo(() => ability.can('view_history', event), [event, ability]);
  const canViewHistoryLog = useMemo(() => ability.can('view_history_log', event), [event, ability]);
  const canRegister = useMemo(
    () => ability.can('register', event) && !isPast(event.start),
    [event, ability],
  );
  const canForceRegisterUsers = useMemo(
    () => ability.can('force_register_user', event),
    [event, ability],
  );
  const canForceRegisterStaffs = useMemo(
    () => ability.can('force_register_staff', event),
    [event, ability],
  );
  const canForceRegisterRooms = useMemo(
    () => ability.can('force_register_room', event),
    [event, ability],
  );

  const value = useMemo(
    () => ({
      canUpdate,
      canDelete,
      canViewHistory,
      canViewHistoryLog,
      canRegister,
      canForceRegisterUsers,
      canForceRegisterStaffs,
      canForceRegisterRooms,
      toRegister: toRegister,
      isAlreadyRegistered: isAlreadyRegistered,
      isGroupMode: isGroupMode,
      eventId: event._id,
      type: event.type,
      title: event.title,
      seats: event.seats,
    }),
    [
      isAlreadyRegistered,
      toRegister,
      isGroupMode,
      canUpdate,
      canDelete,
      canViewHistory,
      canViewHistoryLog,
      canRegister,
      canForceRegisterUsers,
      canForceRegisterStaffs,
      canForceRegisterRooms,
      event._id,
      event.type,
      event.title,
      event.seats,
    ],
  );

  return <EventDetailsContext.Provider value={value}>{children}</EventDetailsContext.Provider>;
}

if (environment.__DEV__) {
  EventDetailsProvider.displayName = 'EventDetailsProvider';
}
