import { FollowUp, ModuleEntry, ProjectGroupEntry } from '@epitech/panoramix-types';
import { GroupingRef, ModuleRef, ProjectGroupRef, Stored } from '@epitech/panoramix-types';
import queryString from 'query-string';

import { environment } from '@/config/environment';

import { panoramixApi } from './baseApi';
import { DEFAULT_PAGINATION, IPaginatedRequest, providesList } from './helpers';

interface IFollowUpProjectGroupGetRequest {
  moduleId: string;
  activityId: string;
  projectGroupId: string;
}

interface IFollowUpProjectGroupPostRequest {
  moduleId: string;
  activityId: string;
  projectGroupId: string;
  followUp: FollowUp;
}

interface IFetchProjectGroup {
  moduleId?: string;
  groupingIds?: string[];
  userId?: string;
}

export interface ISearchModulesRequest extends IPaginatedRequest {
  search?: string;
}

interface ISearchGroupingsRequest extends IPaginatedRequest {
  moduleId: string;
  search: string;
}

interface ISearchProjectGroupsRequest extends IPaginatedRequest {
  moduleId: string;
  groupingIds: string[];
  search: string;
}

export const modulesApi = panoramixApi.injectEndpoints({
  endpoints: builder => ({
    fetchModule: builder.query<ModuleEntry, string>({
      query: (moduleId: string) => `/modules/${moduleId}`,
      providesTags: module => [{ type: 'Modules', id: module?._id }],
    }),
    searchModules: builder.query<ModuleRef[], ISearchModulesRequest>({
      query: ({
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: 'modules',
          query: {
            search,
            refOnly: true,
            limit,
            offset,
          },
        }),
      providesTags: modules => providesList(modules, 'ModulesRef'),
    }),
    searchGroupings: builder.query<GroupingRef[], ISearchGroupingsRequest>({
      query: ({
        moduleId,
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl({
          url: `modules/${moduleId}/groupings`,
          query: {
            search,
            refOnly: true,
            limit,
            offset,
          },
        }),
      providesTags: groupings => providesList(groupings, 'GroupingsRef'),
    }),
    searchProjectGroups: builder.query<ProjectGroupRef[], ISearchProjectGroupsRequest>({
      query: ({
        moduleId,
        groupingIds,
        search,
        limit = DEFAULT_PAGINATION['limit'],
        offset = DEFAULT_PAGINATION['offset'],
      }) =>
        queryString.stringifyUrl(
          {
            url: `modules/${moduleId}/projectGroups`,
            query: {
              search,
              groupingIds,
              refOnly: true,
              limit,
              offset,
            },
          },
          { arrayFormat: environment.QUERY_ARRAY_PARSER },
        ),
      providesTags: projectGroups => providesList(projectGroups, 'ProjectGroupsRef'),
    }),
    getProjectGroup: builder.query<Stored<ProjectGroupEntry>, IFetchProjectGroup>({
      query: ({ moduleId, groupingIds, userId }) =>
        queryString.stringifyUrl(
          {
            url: `modules/${moduleId}/projectGroups`,
            query: {
              groupingIds,
              userIds: [userId],
            },
          },
          { arrayFormat: environment.QUERY_ARRAY_PARSER },
        ),
      transformResponse: (projectGroups: Stored<ProjectGroupEntry>[]) => projectGroups[0],
    }),
    getFollowUpByProjectGroup: builder.query<FollowUp, IFollowUpProjectGroupGetRequest>({
      query: ({ moduleId, activityId, projectGroupId }) =>
        `/modules/${moduleId}/activities/${activityId}/followUps/${projectGroupId}`,
      providesTags: followUp => [{ type: 'FollowUpProjectGroup', id: followUp?.id }],
      keepUnusedDataFor: 10,
    }),
    saveFollowUpByProjectGroup: builder.mutation<FollowUp, IFollowUpProjectGroupPostRequest>({
      query: ({ moduleId, activityId, projectGroupId, followUp }) => ({
        url: `/modules/${moduleId}/activities/${activityId}/followUps/${projectGroupId}`,
        method: 'POST',
        body: followUp,
      }),
      invalidatesTags: followUp => [{ type: 'FollowUpProjectGroup', id: followUp?.id }],
    }),
  }),
});

export const useFetchModuleState = modulesApi.endpoints.fetchModule.useQueryState;

export const {
  useLazySearchModulesQuery,
  useLazySearchGroupingsQuery,
  useLazySearchProjectGroupsQuery,
  useFetchModuleQuery,
  useLazyFetchModuleQuery,
  useGetProjectGroupQuery,
  useLazyGetProjectGroupQuery,
  useGetFollowUpByProjectGroupQuery,
  useLazyGetFollowUpByProjectGroupQuery,
  useSaveFollowUpByProjectGroupMutation,
} = modulesApi;
