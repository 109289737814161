import {
  Box,
  Button,
  HStack,
  Icon,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import type { UserRef } from '@epitech/panoramix-types';
import React from 'react';
import { FiPlus } from 'react-icons/fi';

import { UserAvatar } from '@/components/users/UserAvatar';
import { UserItem } from '@/components/users/UserItem';
import { environment } from '@/config/environment';

type EventSlotUsersListProps = {
  users: UserRef[];
  onDisplayLength?: number;
  emptyLabel: string;
  moreLabel: string;
};

function _EventSlotUsersList({
  users,
  emptyLabel,
  moreLabel,
  onDisplayLength = 4,
}: EventSlotUsersListProps) {
  if (users.length === 0) return <Text>{emptyLabel}</Text>;
  if (users.length === 1) return <UserItem width={'80%'} value={users[0]} fontSize="md" />;

  return (
    <HStack>
      {users.slice(0, onDisplayLength).map(user => {
        return (
          <Tooltip label={user.login} placement="top" key={user._id} hasArrow arrowSize={10}>
            <Box>
              <UserAvatar user={user} size="xs" />
            </Box>
          </Tooltip>
        );
      })}
      {users.length > onDisplayLength && (
        <Popover trigger="hover" placement="top" isLazy={true}>
          <PopoverTrigger>
            <Button
              aria-label={moreLabel}
              size="xs"
              leftIcon={<Icon as={FiPlus} />}
              variant="outline"
              fontFamily="monospace"
              flexShrink={0}
            >
              {users.length - onDisplayLength}
            </Button>
          </PopoverTrigger>
          <PopoverContent width="fit-content">
            <PopoverArrow />
            <PopoverBody as={List} variant="bordered">
              {users.map(user => {
                return <UserItem value={user} px={2} key={user._id} as={ListItem} fontSize="md" />;
              })}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </HStack>
  );
}

export const EventSlotUsersList = React.memo(_EventSlotUsersList);

if (environment.__DEV__) {
  EventSlotUsersList.displayName = 'EventSlotUsersList';
}
