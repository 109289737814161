import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { environment } from '@/config/environment';

import { panoramixApi } from './services/baseApi';
import { rtkQueryErrorLogger, sentryReduxEnhancer } from './middlewares';
import { persistedAuthReducer } from './services/authentication.slice';
import { settingsSlice } from './services/settings.slice';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings'],
  blacklist: [panoramixApi.reducerPath, 'auth'],
};

const rootReducer = combineReducers({
  [panoramixApi.reducerPath]: panoramixApi.reducer,
  auth: persistedAuthReducer,
  settings: settingsSlice.reducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const appStore = configureStore({
  devTools: environment.ENV !== 'production',
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(panoramixApi.middleware, rtkQueryErrorLogger),
  enhancers: defaultEnhancers => {
    return defaultEnhancers.concat(sentryReduxEnhancer);
  },
});

export const persistor = persistStore(appStore);

setupListeners(appStore.dispatch);

export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
