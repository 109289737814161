import { BrowserUtils } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

import { environment } from '@/config/environment';
import { useLogoutMutation } from '@/store/services/users';

export default function () {
  const [logout, { isLoading }] = useLogoutMutation();
  const { instance } = useMsal();

  const handleLogout = useCallback(
    async (callbacks?: Array<() => void>) => {
      await logout().unwrap();
      if (callbacks) {
        callbacks.forEach(cb => cb());
      }
      instance.logoutRedirect({
        postLogoutRedirectUri: environment.office.LOGOUT_REDIRECT_URI,
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      });
    },
    [instance, logout],
  );

  return { handleLogout, isLoading };
}
