import { GroupBase, Select as BaseSelect } from 'chakra-react-select';
import { debounce } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { mergeChakraStyles } from '@/config/theme';

import { getSelectDefaultComponentsOverride } from '../shared';
import { IOption } from '../types';
import { IAsyncSelectProps } from './Async.types';

export function AsyncSelectBase<
  Option extends IOption,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  loadOptions,
  selectRef,
  components = {},
  isSearchable = true,
  isClearable = true,
  backspaceRemovesValue = false,
  isInvalid = false,
  size = 'md',
  displayIcon = true,
  chakraStyles = {},
  infiniteScroll = false,
  ...otherProps
}: IAsyncSelectProps<Option, IsMulti, Group>) {
  const mergedStyles = mergeChakraStyles<Option, IsMulti, Group>(chakraStyles);
  const { t } = useTranslation('components/shared/Select');

  const overridedComponents = React.useMemo(
    () => ({
      ...getSelectDefaultComponentsOverride<Option, IsMulti, Group>(),
      ...components,
    }),
    [components],
  );

  const [options, setOptions] = useState([] as Option[]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchOptions = useCallback(
    async (inputValue: string, currentPage: number) => {
      setIsLoading(true);

      const newOptions = await loadOptions(inputValue, undefined, 10, (currentPage - 1) * 10);
      if (newOptions.length === 0) {
        setHasMore(false);
      } else {
        if (currentPage === 1) {
          setOptions(newOptions);
        } else {
          setOptions(prevOptions => [...prevOptions, ...newOptions]);
        }
        setPage(() => currentPage + 1);
      }
      setIsLoading(false);
    },
    [loadOptions],
  );

  const onInputChange = debounce((inputValue: string) => {
    if (inputValue !== searchQuery) {
      setHasMore(true);
      setOptions([]);
      setSearchQuery(inputValue);
      fetchOptions(inputValue, 1);
    }
  }, 1000);

  const handleScroll = debounce(async (event: WheelEvent | TouchEvent) => {
    const target = event.target;

    if (!(target instanceof HTMLElement)) {
      return;
    }

    const bottom = target.scrollHeight === target.scrollTop + target.clientHeight;
    if (bottom && !isLoading && hasMore) {
      await fetchOptions(searchQuery, page);
    }
  }, 500);

  const onMenuOpen = useCallback(() => {
    setSearchQuery('');
    setOptions([]);
    fetchOptions(searchQuery, 1);
  }, [fetchOptions, searchQuery]);

  return (
    <BaseSelect
      isInvalid={isInvalid}
      ref={selectRef}
      filterOption={otherProps.filterOption || (() => true)}
      onInputChange={onInputChange}
      onMenuOpen={onMenuOpen}
      options={options}
      placeholder={!isLoading && !options.length ? t('placeholder_label') : t('loading_msg')}
      loadingMessage={() => t('loading_msg')}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isLoading={isLoading}
      backspaceRemovesValue={backspaceRemovesValue}
      onMenuScrollToBottom={infiniteScroll ? handleScroll : undefined}
      size={size}
      components={{
        ...overridedComponents,
        DropdownIndicator: displayIcon ? overridedComponents.DropdownIndicator : undefined,
      }}
      {...otherProps}
      chakraStyles={mergedStyles}
    />
  );
}
