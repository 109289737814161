import type { EventCourseEntry } from '@epitech/panoramix-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { GroupItem } from '@/components/groups/GroupItem';
import { GroupOption, type IProjectGroupOption, useGroupSearch } from '@/components/groups/select';
import { SearchMenuMulti } from '@/components/ui-elements/SearchMenu/Multi';
import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { environment } from '@/config/environment';
import type { Stored } from '@/lib/types';
import { useForceRegisterToEventMutation } from '@/store/services/events';

export type ForceRegisterGroupsCourseProps = {
  event: Stored<EventCourseEntry>;
  onClose: () => void;
};

function _ForceRegisterGroupsCourse({ event, onClose }: ForceRegisterGroupsCourseProps) {
  const searchProjectGroups = useGroupSearch(event.moduleRef);
  const [forceRegisterToEvent, { isLoading: isRegisterLoading }] =
    useForceRegisterToEventMutation();
  const { t } = useTranslation('components/events');

  const onSubmitHandler = useCallback(
    (selectedOptions: IProjectGroupOption[]) => {
      const selectedGroups = selectedOptions.map(opt => ({
        ...opt.value,
        tpIds: opt.value.tpIds || [],
      }));
      forceRegisterToEvent({
        eventId: event._id!,
        toRegisters: selectedGroups,
        type: 'projectGroups',
      });
      onClose();
    },
    [onClose, forceRegisterToEvent, event._id],
  );

  const filterOption = useCallback(
    (option: FilterOptionOption<IProjectGroupOption>) => {
      return !event?.registeredGroups.some(group => group._id === option.data.value._id);
    },
    [event?.registeredGroups],
  );

  const onNoOptions = useCallback(() => t('empty_group-search'), [t]);

  return (
    <SearchMenuMulti<IProjectGroupOption>
      name="forceRegisterGroups"
      loadOptions={searchProjectGroups}
      onSubmit={onSubmitHandler}
      components={{
        Option: GroupOption,
        Value: GroupItem,
      }}
      isLoading={isRegisterLoading}
      noOptionsMessage={onNoOptions}
      filterOption={filterOption}
      infiniteScroll={true}
    />
  );
}

export const ForceRegisterGroupsCourse = React.memo(_ForceRegisterGroupsCourse);

if (environment.__DEV__) {
  ForceRegisterGroupsCourse.displayName = 'ForceRegisterGroupsCourse';
}
