import type { ModuleRef } from '@epitech/panoramix-types';
import { useCallback } from 'react';

import { type ISearchModulesRequest, useLazySearchModulesQuery } from '@/store/services/modules';

import { getModuleOption, IModuleOption } from './helpers';

type GetModuleOption = (module: ModuleRef) => IModuleOption;

export function useModuleSearch(
  params?: ISearchModulesRequest,
  getOption: GetModuleOption = getModuleOption,
) {
  const [searchModules] = useLazySearchModulesQuery();

  return useCallback(
    async (
      search: string,
      callback?: (options: IModuleOption[]) => void | Promise<IModuleOption[]>,
      limit?: number,
      offset?: number,
    ) => {
      const result = await searchModules(
        {
          ...params,
          search,
          limit,
          offset,
        },
        true,
      ).unwrap();
      return result.map(getOption);
    },
    [searchModules, params, getOption],
  );
}
