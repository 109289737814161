import { Icon, IconButton, ListItem, useToast } from '@chakra-ui/react';
import { UserRef } from '@epitech/panoramix-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FiX } from 'react-icons/fi';

import { ConfirmPopover } from '@/components/ui-elements/ConfirmPopover';
import { UserItem } from '@/components/users/UserItem';
import { BASE_OPACITY_TRANSITION } from '@/config/theme';
import { useForceUnregisterFromEventMutation } from '@/store/services/events';

import { RegisteredItemProps } from './types';

interface RegisteredStaffItemProps extends RegisteredItemProps {
  user: UserRef;
}

function _RegisteredStaffItem({
  user: userRef,
  eventId,
  slotIdx,
  canForceUnregister,
  unregisterBtnOnHover = true,
  hasPortal = true,
}: RegisteredStaffItemProps): React.ReactElement {
  const { t } = useTranslation('components/events');

  const [forceUnregisterFromEvent, { isLoading: isUnregisterLoading }] =
    useForceUnregisterFromEventMutation();
  const toast = useToast();
  const onUnregisterStaff = React.useCallback(async () => {
    await forceUnregisterFromEvent({
      eventId,
      toRegisters: [userRef],
      slotIdx,
      type: 'staffs',
    }).unwrap();
    toast({
      description: t('staff_unregister_success'),
      status: 'success',
      position: 'top',
    });
  }, [forceUnregisterFromEvent, toast, t, eventId, userRef, slotIdx]);

  const btnStyles = useMemo(
    () =>
      unregisterBtnOnHover
        ? {
            opacity: 0,
            transition: BASE_OPACITY_TRANSITION,
            _groupHover: {
              opacity: 1,
            },
          }
        : {},
    [unregisterBtnOnHover],
  );

  return (
    <ListItem
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      w="full"
      gap={2}
      role="group"
    >
      <UserItem value={userRef} />
      {canForceUnregister && (
        <ConfirmPopover
          hasPortal={hasPortal}
          onConfirm={onUnregisterStaff}
          isLoading={isUnregisterLoading}
          confirmLabel={t('unregister_confirm')}
          confirmButtonLabel={t('unregister_btn_label')}
          resourceName={userRef.firstName}
          renderTrigger={() => (
            <IconButton
              flexShrink={0}
              icon={<Icon as={FiX} />}
              colorScheme="red"
              variant="ghost"
              aria-label="unregister"
              size="xs"
              ml="auto"
              {...btnStyles}
            />
          )}
        />
      )}
    </ListItem>
  );
}

export const RegisteredStaffItem = React.memo<RegisteredStaffItemProps>(_RegisteredStaffItem);
