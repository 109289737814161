import { ButtonGroup, HStack, Td, Text, Tr, useToast } from '@chakra-ui/react';
import type { EventAppointmentMultipleEntry } from '@epitech/panoramix-types';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EventSlotGroupsList } from '@/components/events/slots/GroupsList';
import { ForceRegisterAppointmentMultipleActionButton } from '@/components/events/slots/RegisteredList/RegisteredItem/appointments/multiple/ForceRegisterActionButton';
import { TeamsButton } from '@/components/ui-elements/Buttons/TeamsButton';
import { environment } from '@/config/environment';
import { BASE_OPACITY_TRANSITION } from '@/config/theme/components/others/UserItem';
import {
  useRegisterToEventMutation,
  useUnregisterFromEventMutation,
} from '@/store/services/events';

import { EventSlotUsersList } from '../../../../UsersList';
import {
  ForceRegisterStaffsActionButton,
  RoomActionButton,
  StudentActionButton,
} from '../../Actions';
import { SlotRegisteredItemBaseProps } from '../types';

export interface SlotRegisteredItemAppointmentMultipleProps extends SlotRegisteredItemBaseProps {
  maxRegistrationsBySlot: number;
  slot: EventAppointmentMultipleEntry['slots'][number];
}

function _SlotRegisteredItemAppointmentMultiple({
  eventId,
  slot,
  slotIdx,
  allowRegistration,
  toRegister,
  isGroupMode,
  isAlreadyRegistered,
  maxRegistrationsBySlot,
  canForceRegisterRooms = false,
  canForceRegisterUsers = false,
  canForceRegisterStaffs = false,
}: SlotRegisteredItemAppointmentMultipleProps) {
  const { t } = useTranslation('components/events');
  const toast = useToast();
  const [registerToEvent, { isLoading: isRegisterLoading }] = useRegisterToEventMutation();
  const [unregisterFromEvent, { isLoading: isUnregisterLoading }] =
    useUnregisterFromEventMutation();

  const isLoading = isRegisterLoading || isUnregisterLoading;

  const onRegister = useCallback(async () => {
    if (!toRegister) {
      return;
    }
    /** We can be sure that `toRegister` will be defined at the moment of the call from {@link SlotItem['getActionButtons']} */
    await registerToEvent({
      eventId,
      toRegister,
      type: isGroupMode ? 'projectGroups' : 'users',
      slotIdx: slotIdx,
    }).unwrap();

    toast({
      description: t('success_inscription'),
      status: 'success',
      position: 'top',
    });
  }, [eventId, toRegister, registerToEvent, toast, t, isGroupMode, slotIdx]);

  const onUnregister = useCallback(async () => {
    if (!toRegister) {
      return;
    }
    await unregisterFromEvent({
      eventId,
      type: isGroupMode ? 'projectGroups' : 'users',
      toRegister,
      slotIdx: slotIdx,
    }).unwrap();

    toast({
      description: t('success_desinscription'),
      status: 'success',
      position: 'top',
    });
  }, [eventId, toRegister, unregisterFromEvent, toast, isGroupMode, t, slotIdx]);

  const renderRegisteredItem = useMemo(() => {
    if (slot.registeredGroups?.length) {
      return <EventSlotGroupsList groups={slot.registeredGroups} overflow="hidden" flex={1} />;
    }
    if (slot.registeredUsers?.length) {
      return (
        <EventSlotUsersList
          users={slot.registeredUsers}
          emptyLabel={t('no_student')}
          moreLabel={t('more_students')}
        />
      );
    }
    return <Text lineHeight="base">{t('no_participant')}</Text>;
  }, [slot.registeredUsers, slot.registeredGroups, t]);

  const dateFormat = t('date_format');
  const hourFormat = t('hour_format');
  const isSlotFull = !!(
    maxRegistrationsBySlot &&
    (isGroupMode
      ? slot.registeredGroups?.length >= maxRegistrationsBySlot
      : slot.registeredUsers?.length >= maxRegistrationsBySlot)
  );

  const isUserSlot =
    (isGroupMode
      ? slot.registeredGroups?.some(group => group._id === toRegister?._id)
      : slot.registeredUsers?.some(user => user._id === toRegister?._id)) || false;

  return (
    <Tr role="group">
      <Td fontFamily="mono">{format(slot.start, dateFormat)}</Td>
      <Td fontFamily="mono">
        {format(slot.start, hourFormat)} - {format(slot.end, hourFormat)}
      </Td>
      <Td>
        <HStack justify="space-between">
          <EventSlotUsersList
            users={slot.registeredStaffs}
            emptyLabel={t('no_staff')}
            moreLabel={t('more_staffs')}
          />
          {canForceRegisterStaffs && (
            <ForceRegisterStaffsActionButton eventId={eventId} slot={slot} slotIdx={slotIdx} />
          )}
        </HStack>
      </Td>
      <Td>
        <HStack justify="space-between" gap={2}>
          {renderRegisteredItem}
          {canForceRegisterUsers ? (
            <ButtonGroup
              size="xs"
              opacity={0}
              transition={BASE_OPACITY_TRANSITION}
              _groupHover={{
                opacity: 1,
              }}
            >
              <TeamsButton
                users={isGroupMode ? [] : slot.registeredUsers?.map(user => user.login)}
              />
              <ForceRegisterAppointmentMultipleActionButton
                userType={isGroupMode ? 'groups' : 'users'}
                eventId={eventId}
                slot={slot}
                slotIdx={slotIdx}
                isLoading={isLoading}
                isSlotFull={isSlotFull}
              />
            </ButtonGroup>
          ) : toRegister && ((!isAlreadyRegistered && !isSlotFull) || isUserSlot) ? (
            <StudentActionButton
              isGroupMode={isGroupMode}
              toRegister={toRegister}
              isPast={isPast(slot.start)}
              isUserSlot={isUserSlot}
              isLoading={isLoading}
              allowRegistration={allowRegistration && !isSlotFull}
              onRegister={onRegister}
              onUnregister={onUnregister}
            />
          ) : null}
        </HStack>
      </Td>
      <Td>
        <HStack justify="space-between">
          {slot?.roomRef ? (
            <Text
              title={`${slot.roomRef.name}`}
              width={'80%'}
              textOverflow={'ellipsis'}
              overflow={'hidden'}
            >{`${slot.roomRef.name}`}</Text>
          ) : (
            <Text>{t('no_room')}</Text>
          )}
          {canForceRegisterRooms && (
            <RoomActionButton
              eventId={eventId}
              slot={slot}
              slotIdx={slotIdx}
              isSlotFull={!!slot?.roomRef}
              size="xs"
              opacity={0}
              transition={BASE_OPACITY_TRANSITION}
              _groupHover={{
                opacity: 1,
              }}
            />
          )}
        </HStack>
      </Td>
    </Tr>
  );
}

export const SlotRegisteredItemAppointmentMultiple = React.memo(
  _SlotRegisteredItemAppointmentMultiple,
);

if (environment.__DEV__) {
  SlotRegisteredItemAppointmentMultiple.displayName = 'SlotRegisteredItemAppointmentMultiple';
}
