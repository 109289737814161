import { chakraComponents, OptionProps } from 'chakra-react-select';
import React from 'react';

import { UserItem } from '../UserItem';
import type { IUserOption } from './helpers';

export function UserOption<IsMulti extends boolean = false>(
  props: React.PropsWithChildren<OptionProps<IUserOption, IsMulti>>,
) {
  const { data } = props;

  return (
    <chakraComponents.Option {...props}>
      <UserItem value={data.value} />
    </chakraComponents.Option>
  );
}
