import {
  Button,
  HStack,
  Icon,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  StackDivider,
  type StackProps,
  Text,
} from '@chakra-ui/react';
import type { ProjectGroupRef } from '@epitech/panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

import { GroupItem } from '@/components/groups';
import { environment } from '@/config/environment';

type EventSlotUsersListProps = {
  groups: ProjectGroupRef[];
  onDisplayLength?: number;
};

function _EventSlotGroupsList({
  groups,
  onDisplayLength = 2,
  ...stackProps
}: EventSlotUsersListProps & StackProps) {
  const { t } = useTranslation('components/events');
  const hasPopover = groups.length > onDisplayLength;

  if (groups.length === 0) return <Text>{t('no_group')}</Text>;
  if (groups.length === 1) return <GroupItem width={'80%'} value={groups[0]} />;

  return (
    <HStack
      gap={2}
      justifyContent="space-between"
      {...stackProps}
      as={hasPopover ? undefined : React.Fragment}
    >
      <HStack
        gap={0}
        alignItems="center"
        divider={
          <StackDivider transform="rotate(20deg) translateX(-3px)" h={4} alignSelf="center" />
        }
        flexShrink={1}
        minW={0}
        {...(hasPopover ? {} : stackProps)}
      >
        {groups.slice(0, onDisplayLength).map(group => {
          return (
            <GroupItem
              key={group._id}
              value={group}
              popoverBtnOnHover={false}
              fontSize="md"
              gap={1}
              minW={0}
              flexBasis="fit-content"
              flexGrow="initial"
            />
          );
        })}
      </HStack>
      {groups.length > onDisplayLength && (
        <Popover trigger="hover" placement="top" isLazy={true}>
          <PopoverTrigger>
            <Button
              leftIcon={<Icon as={FiPlus} />}
              aria-label={t('more_groups')}
              size="xs"
              variant="outline"
              fontFamily="monospace"
              flexShrink={0}
            >
              {groups.length - onDisplayLength}
            </Button>
          </PopoverTrigger>
          <PopoverContent width="fit-content">
            <PopoverArrow />
            <PopoverBody as={List} variant="bordered">
              {groups.map(group => {
                return (
                  <GroupItem value={group} px={2} key={group._id} as={ListItem} fontSize="sm" />
                );
              })}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </HStack>
  );
}

export const EventSlotGroupsList = React.memo(_EventSlotGroupsList);

if (environment.__DEV__) {
  EventSlotGroupsList.displayName = 'EventSlotGroupsList';
}
