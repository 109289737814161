import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Badge,
  HStack,
  Tag,
  Text,
} from '@chakra-ui/react';
import { HistoryEntry } from '@epitech/panoramix-types';
import { formatDate } from '@fullcalendar/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/config/environment';

import { EventHistoryLogasUserItem } from './UserItem';

interface EventHistoryEntryItemProps {
  historyEntry: HistoryEntry;
}
function _EventHistoryEntryItem({
  historyEntry,
  ...accordionItemProps
}: EventHistoryEntryItemProps & AccordionItemProps) {
  const { t } = useTranslation('components/events/history');
  const hasHistoryLog = !!historyEntry.log;

  return (
    <AccordionItem position="relative" {...accordionItemProps}>
      <AccordionButton
        bgColor="secondBgColor"
        _hover={{
          bgColor: 'buttonBgColorDarkHover',
        }}
        position="sticky"
        top={0}
        w="full"
        justifyContent="space-between"
        gap={2}
        cursor={hasHistoryLog ? 'pointer' : 'default'}
      >
        <HStack w="full">
          <Tag fontFamily="monospace">{t(historyEntry.action)}</Tag>
          <Text> {t('action_by')} </Text>
          <EventHistoryLogasUserItem
            userRef={historyEntry.userRef}
            logasUserRef={historyEntry.logasUserRef}
          />
          <Badge ml="auto" fontFamily="monospace">
            {formatDate(historyEntry.date, {
              locale: t('language'),
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              omitCommas: true,
            })}
          </Badge>
        </HStack>
        {hasHistoryLog && <AccordionIcon />}
      </AccordionButton>
      {hasHistoryLog && (
        <AccordionPanel
          layerStyle="container"
          as="pre"
          w="auto"
          maxW="37rem"
          mx="auto"
          mb={2}
          maxH="md"
          overflow="auto"
          fontFamily="monospace"
        >
          {JSON.stringify(historyEntry.log, null, 2)}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
}

export const EventHistoryEntryItem = React.memo(_EventHistoryEntryItem);

if (environment.__DEV__) {
  EventHistoryEntryItem.displayName = 'EventHistoryEntryItem';
}
