import { Grid } from "@chakra-ui/react";
import { RoomEvents } from "@epitech/panoramix-types";
import React, { useEffect } from "react";

import { RoomStatusProps } from "../RoomStatusContainer";
import { RoomGridItem } from "./GridItem";

function _RoomGrid({rooms, autoScroll, autoScrollCallback, autoScrollTime}: RoomStatusProps): React.ReactElement {
  useEffect(() => {
    if (!autoScroll || !autoScrollCallback || !autoScrollTime) {
      return;
    }
    const timer = setTimeout(() => {
      autoScrollCallback();
    }, autoScrollTime);
    return () => clearTimeout(timer);
  }, [autoScroll, autoScrollCallback, autoScrollTime])

  return (
    <Grid
        gridTemplateRows="min-content min-content min-content min-content"
        gridTemplateColumns={{sm: '1fr', md: '1fr 1fr 1fr', xl: '1fr 1fr 1fr 1fr 1fr'}}
        gridGap="2rem 2rem"
      >
      {
        rooms?.map((el: RoomEvents) => (
          <RoomGridItem
            key={el._id}
            name={el.name}
            events={el.events}
            nowTimeStamp={Date.now()}
          />
        ))
      }
      </Grid>
  );
}

export const RoomGrid = React.memo<RoomStatusProps>(_RoomGrid);
