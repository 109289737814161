import type { ModuleRef } from '@epitech/panoramix-types';
import { addDays, startOfDay } from 'date-fns';

import type { IModuleOption } from '@/components/modules/select/helpers';

export function isFullDayEvent(startDate: Date, endDate: Date): boolean {
  const start = startOfDay(startDate);
  const end = addDays(start, 1);

  return start.getTime() === startDate.getTime() && end.getTime() === endDate.getTime();
}

/** Necessary since we are getting options from rtk-query and they are immutable by default.
 * This function is used to clone the module option and make it mutable to add `activityRef` and `groupingsRef` fields.
 */
export const getClonedModuleOption = (module: ModuleRef): IModuleOption => ({
  value: structuredClone(module),
  label: module.name,
});
