import {
  Alert,
  AlertIcon,
  ListItem,
  ListProps,
  Spinner,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import type { Stored, UserRef } from '@epitech/panoramix-types';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/config/environment';
import { useSearchUsersQuery } from '@/store/services/users';

import { UserItem } from '../UserItem';
import { IUserSearchForm } from './types';

export interface IUserSearchFormPreviewProps {
  formValues: IUserSearchForm;
  setNbOfUsers: React.Dispatch<React.SetStateAction<number | null>>;
  filterUsers?: (user: Stored<UserRef>) => boolean;
}

function _UserSearchFormPreview({
  formValues: { cohortGroups, moduleRef },
  filterUsers,
  setNbOfUsers,
  ...listProps
}: IUserSearchFormPreviewProps & ListProps) {
  const { t } = useTranslation('components/events');
  const { data: rawUsers = [], isLoading } = useSearchUsersQuery(
    {
      /** Necessary as react-hook-form cannot mutate query args */
      ...structuredClone({
        cohortGroups,
        moduleRef,
      }),
      limit: 0,
    },
    { skip: !cohortGroups.length && !moduleRef },
  );

  const users = useMemo(
    () => (filterUsers ? rawUsers.filter(filterUsers) : rawUsers),
    [rawUsers, filterUsers],
  );

  useEffect(() => {
    setNbOfUsers(users.length);
    return () => {
      setNbOfUsers(null);
    };
  }, [users.length, setNbOfUsers]);

  if (!users.length && !isLoading) {
    return (
      <Alert fontFamily="mono" textAlign="center" lineHeight={1} status="warning">
        <AlertIcon />
        <Text>{t('empty_user-search')}</Text>
      </Alert>
    );
  }

  return (
    <UnorderedList
      variant="bordered"
      maxHeight={300}
      overflowY="auto"
      mx={0}
      px={2}
      layerStyle="container"
      {...listProps}
    >
      {isLoading && <Spinner />}
      {users.map(user => (
        <UserItem as={ListItem} key={user._id} value={user} />
      ))}
    </UnorderedList>
  );
}

export const UserSearchFormPreview = React.memo(_UserSearchFormPreview);

if (environment.__DEV__) {
  UserSearchFormPreview.displayName = 'UserSearchFormPreview';
}
