import type { EventAppointmentEntry } from '@epitech/panoramix-types';
import { Stored } from '@epitech/panoramix-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchMenu } from '@/components/ui-elements/SearchMenu/Single';
import { FilterOptionOption } from '@/components/ui-elements/Select/types';
import { type IUserOption, UserOption, UserValue, useUserSearch } from '@/components/users/select';
import { environment } from '@/config/environment';
import { useForceRegisterToEventMutation } from '@/store/services/events';

export type ForceRegisterAppointmentSingleUserProps = {
  event: Stored<EventAppointmentEntry>;
  onClose: () => void;
  slotIdx: number;
};

function _ForceRegisterAppointmentSingleUser({
  event,
  onClose,
  slotIdx,
}: ForceRegisterAppointmentSingleUserProps) {
  const searchUsers = useUserSearch({
    moduleRef: event.moduleRef,
    cohortGroups: event.cohortGroups,
  });
  const [forceRegisterToEvent, { isLoading: isRegisterLoading }] =
    useForceRegisterToEventMutation();
  const { t } = useTranslation('components/events');

  const onSubmitHandler = useCallback(
    (selectedUser: IUserOption['value']) => {
      forceRegisterToEvent({
        eventId: event._id!,
        toRegisters: [selectedUser],
        slotIdx,
        type: 'users',
      });
      onClose();
    },
    [onClose, event._id, forceRegisterToEvent, slotIdx],
  );

  const filterOption = useCallback(
    (option: FilterOptionOption<IUserOption>) => {
      return !event?.slots.some(slot => slot.registeredUser?._id === option.data.value._id);
    },
    [event?.slots],
  );

  const onNoOptions = useCallback(() => t('empty_user-search'), [t]);

  return (
    <SearchMenu<IUserOption>
      name="forceRegisterUser"
      loadOptions={searchUsers}
      onSubmit={onSubmitHandler}
      filterOption={filterOption}
      isLoading={isRegisterLoading}
      noOptionsMessage={onNoOptions}
      components={{
        SingleValue: UserValue,
        Option: UserOption,
      }}
      infiniteScroll={true}
    />
  );
}

export const ForceRegisterAppointmentSingleUser = React.memo(_ForceRegisterAppointmentSingleUser);

if (environment.__DEV__) {
  ForceRegisterAppointmentSingleUser.displayName = 'ForceRegisterAppointmentSingleUser';
}
