import type { EventEntry } from '@epitech/panoramix-types';
import { useCallback } from 'react';

import { useLazySearchProjectGroupsQuery } from '@/store/services/modules';

import { getProjectGroupOption, IProjectGroupOption } from './helpers';

export function useGroupSearch(moduleRef: EventEntry['moduleRef']) {
  const [searchProjectGroups] = useLazySearchProjectGroupsQuery();

  return useCallback(
    async (
      search: string,
      callback?: (options: IProjectGroupOption[]) => void | Promise<IProjectGroupOption[]>,
      limit?: number,
      offset?: number,
    ) => {
      if (moduleRef) {
        const result = await searchProjectGroups(
          {
            search,
            moduleId: moduleRef._id,
            groupingIds: moduleRef?.groupingsRef?.map(g => g._id) || [],
            limit,
            offset,
          },
          true,
        ).unwrap();
        return result.map(getProjectGroupOption);
      }
      return [];
    },
    [searchProjectGroups, moduleRef],
  );
}
