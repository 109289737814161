import type { StoredEventEntry } from '@epitech/panoramix-types';
import React from 'react';

import {
  ForceRegisterGroupsAppointmentMultiple,
  ForceRegisterGroupsAppointmentMultipleProps,
} from '@/components/events/force-register/groups/appointments/Multiple';
import {
  ForceRegisterGroupAppointmentSingle,
  type ForceRegisterGroupAppointmentSingleProps,
} from '@/components/events/force-register/groups/appointments/Single';
import { environment } from '@/config/environment';

import { ForceRegisterGroupsCourse, ForceRegisterGroupsCourseProps } from './Course';

interface ForceRegisterGroupsProps
  extends Omit<
    ForceRegisterGroupsCourseProps &
      ForceRegisterGroupAppointmentSingleProps &
      ForceRegisterGroupsAppointmentMultipleProps,
    'event' | 'slotIdx'
  > {
  event: StoredEventEntry;
  slotIdx?: number;
}

function _ForceRegisterGroups(props: ForceRegisterGroupsProps) {
  const { event } = props;

  switch (event.type) {
    case 'course':
      return <ForceRegisterGroupsCourse {...props} event={event} />;
    case 'appointment':
      if (props.slotIdx === undefined || isNaN(props.slotIdx)) {
        throw new Error('slotIdx is required for forceRegisterGroups appointment');
      }
      return (
        <ForceRegisterGroupAppointmentSingle {...props} event={event} slotIdx={props.slotIdx} />
      );
    case 'appointment_multiple':
      if (props.slotIdx === undefined || isNaN(props.slotIdx)) {
        throw new Error('slotIdx is required for forceRegisterGroups appointment_multiple');
      }
      return (
        <ForceRegisterGroupsAppointmentMultiple {...props} event={event} slotIdx={props.slotIdx} />
      );
    default:
      return null;
  }
}

export const ForceRegisterGroups = React.memo(_ForceRegisterGroups);

if (environment.__DEV__) {
  ForceRegisterGroups.displayName = 'ForceRegisterGroups';
}
