import { Td, Tr } from '@chakra-ui/react';
import { EventSlotEntry } from '@epitech/panoramix-types';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/config/environment';

interface ISlotProps {
  slot: EventSlotEntry;
}

function _SlotRegisteredItemBreak({ slot }: ISlotProps) {
  const { t } = useTranslation('components/events');

  const hourFormat = t('hour_format');
  return (
    <Tr role="group">
      <Td className="breaks" colSpan={5}>
        {t('break')} {format(slot.start, hourFormat)} - {format(slot.end, hourFormat)}
      </Td>
    </Tr>
  );
}

export const SlotRegisteredItemBreak = React.memo(_SlotRegisteredItemBreak);

if (environment.__DEV__) {
  SlotRegisteredItemBreak.displayName = 'SlotRegisteredItemBreak';
}
