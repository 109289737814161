import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Button, ButtonGroup, ModalFooter, Textarea, useToast, VStack } from '@chakra-ui/react';
import { FollowUp } from '@epitech/panoramix-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormNumberSliderInput } from '@/components/ui-elements/FormNumberSliderInput';
import { useSaveFollowUpByProjectGroupMutation } from '@/store/services/modules';

import ListUsers from './ListUsers';

interface IProps {
  moduleId: string;
  activityId: string;
  projectGroupId: string;
  followUp: FollowUp;
  onClose: () => void;
}

function _FollowUpFormContent({ moduleId, activityId, projectGroupId, followUp, onClose }: IProps) {
  const [saveFollowUp, { isLoading }] = useSaveFollowUpByProjectGroupMutation();

  const { t } = useTranslation('components/followups/followups');
  const toast = useToast();

  const formMethods = useForm<FollowUp>({
    defaultValues: {
      tags: [],
      moral: undefined,
      experience_school: undefined,
      experience_enterprise: undefined,
    },
  });

  const { reset } = formMethods;

  useEffect(() => {
    if (followUp) {
      const resetFollowUp = {
        ...followUp,
        tags: followUp.tags.map(tag => ({
          type: tag.type,
          foreign_id: tag.foreign_id,
          present: tag.present || false,
        })),
        moral: followUp.moral || undefined,
        experience_school: followUp.experience_school || undefined,
        experience_enterprise: followUp.experience_enterprise || undefined,
      };

      if (resetFollowUp.completion === null || resetFollowUp.completion === undefined) {
        delete resetFollowUp.completion;
      }

      reset(resetFollowUp);
    }
  }, [followUp, reset]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = formMethods;

  const listUsers = useMemo(() => {
    if (followUp) {
      return followUp.tags.reduce(
        (acc, tag, index) => {
          if (tag.type === 'user') {
            return [
              ...acc,
              {
                id: tag.foreign_id,
                name: tag.label!,
                present: !!tag.present,
                realIndex: index,
              },
            ];
          }
          return acc;
        },
        [] as Array<{
          id: number;
          name: string;
          present: boolean;
          realIndex: number;
        }>,
      );
    }

    return [];
  }, [followUp]);

  const onSubmit = useCallback(
    async (followUp: FollowUp) => {
      await saveFollowUp({
        moduleId,
        activityId,
        projectGroupId,
        followUp,
      }).unwrap();

      toast({
        description: 'Follow-up saved',
        status: 'success',
        position: 'top',
      });
    },
    [activityId, moduleId, projectGroupId, saveFollowUp, toast],
  );

  const onSubmitAndQuit = useCallback(
    async (followUp: FollowUp) => {
      await onSubmit(followUp);
      onClose();
    },
    [onClose, onSubmit],
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} p={6} pt={0} align="start">
          <ListUsers users={listUsers} />
          <FormControl label={t('Notes')} isInvalid={!!errors.notes}>
            <FormLabel>{t('Notes')}</FormLabel>
            <Textarea variant="white" {...register('notes')} />
            <FormErrorMessage>{errors.notes && errors.notes.message}</FormErrorMessage>
          </FormControl>
          <FormControl label={t('Completion')} isInvalid={!!errors.completion}>
            <FormLabel>{t('Completion')}</FormLabel>
            <FormNumberSliderInput
              allowMouseWheel
              hasStepper
              variant="white"
              maxW="100px"
              name="completion"
              min={0}
              max={100}
              step={5}
              control={control}
            />
            <FormErrorMessage>{errors.completion && errors.completion.message}</FormErrorMessage>
          </FormControl>
          {followUp?.moral !== undefined && (
            <FormControl label={t('Moral')}>
              <FormLabel>{t('Moral')}</FormLabel>
              <FormNumberSliderInput
                allowMouseWheel
                hasStepper
                variant="white"
                maxW="100px"
                name="moral"
                min={0}
                max={5}
                control={control}
              />
              <FormErrorMessage>{errors.moral && errors.moral.message}</FormErrorMessage>
            </FormControl>
          )}
          {followUp?.experience_school !== undefined && (
            <FormControl label={t('Experience School')}>
              <FormLabel>{t('Experience School')}</FormLabel>
              <FormNumberSliderInput
                allowMouseWheel
                hasStepper
                variant="white"
                maxW="100px"
                name="experience_school"
                min={0}
                max={5}
                control={control}
              />
              <FormErrorMessage>
                {errors.experience_school && errors.experience_school.message}
              </FormErrorMessage>
            </FormControl>
          )}
          {followUp?.experience_enterprise !== undefined && (
            <FormControl label={t('Experience Enterprise')}>
              <FormLabel>{t('Experience Enterprise')}</FormLabel>
              <FormNumberSliderInput
                allowMouseWheel
                hasStepper
                variant="white"
                maxW="100px"
                name="experience_enterprise"
                min={0}
                max={5}
                control={control}
              />
              <FormErrorMessage>
                {errors.experience_enterprise && errors.experience_enterprise.message}
              </FormErrorMessage>
            </FormControl>
          )}
        </VStack>
        <ModalFooter display="flex" flexDir="row" alignItems="center">
          <ButtonGroup colorScheme="blue">
            <Button onClick={handleSubmit(onSubmit)} isDisabled={isLoading}>
              {t('Save')}
            </Button>
            <Button onClick={handleSubmit(onSubmitAndQuit)} isDisabled={isLoading}>
              {t('Save and Quit')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </FormProvider>
  );
}

const FollowUpFormContent = React.memo(_FollowUpFormContent);

export default FollowUpFormContent;
