import { Accordion, Box, Flex } from '@chakra-ui/react';
import { ActivityCityEvents } from '@epitech/panoramix-types';
import { ModuleEntry } from '@epitech/panoramix-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterLabel } from '@/components/calendar/filters/Label';
import { useSearchEventsWithModuleIdsQuery } from '@/store/services/events';

import { ActivityItem } from './ActivityItem';

interface ActivityListProps {
  module: ModuleEntry;
}

function _ActivityList({ module }: ActivityListProps) {
  const { t } = useTranslation('components/events');
  const searchEvents = useSearchEventsWithModuleIdsQuery(
    { moduleId: module?._id },
    { refetchOnMountOrArgChange: true },
  );
  const activityEvent = useMemo(() => {
    if (!searchEvents?.data || !module) {
      return;
    }
    const events_activity: string[] = searchEvents?.data.map(ev => ev._id);
    const allActivity: ActivityCityEvents[] = module?.activitiesRef
      .map(activity => ({ _id: activity.name, cities: [] }))
      .filter(elem => !events_activity.includes(elem._id));
    return searchEvents.data.concat(allActivity);
  }, [searchEvents.data, module]);

  return activityEvent && activityEvent.length > 0 ? (
    <Box mt={4}>
      <FilterLabel label="activitiesModuleView" />
      <Accordion allowToggle background={'containerBgColor'} allowMultiple borderRadius={4}>
        {activityEvent.map(activity => {
          return <ActivityItem key={activity._id} events={activity.cities} title={activity._id} />;
        })}
      </Accordion>
    </Box>
  ) : (
    <Flex m={4} justifyContent={'center'}>
      {t('no_event_for_module')}
    </Flex>
  );
}

export const ActivityList = React.memo(_ActivityList);
