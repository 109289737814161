import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ProjectGroupRef, UserRef } from '@epitech/panoramix-types';
import React, { type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { environment } from '@/config/environment';

import { LabelData } from '../ui-elements/LabelData';
import { EventRegisteredItem } from './registered';

interface IEventRegisterActionButtonProps {
  isGroupMode: boolean;
  toRegister: UserRef | ProjectGroupRef;
}

function _EventRegisterAsWrapper({
  isGroupMode,
  toRegister,
  children,
}: PropsWithChildren<IEventRegisterActionButtonProps>) {
  const { t } = useTranslation('components/events');

  return (
    <Popover trigger="hover" isLazy={true}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverArrow />
        <PopoverBody
          as={LabelData}
          label={t('subscribe_as')}
          fontSize="sm"
          spacing={2}
          p={2}
          px={4}
        >
          <EventRegisteredItem isGroupMode={isGroupMode} item={toRegister} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export const EventRegisterAsWrapper = React.memo(_EventRegisterAsWrapper);

if (environment.__DEV__) {
  EventRegisterAsWrapper.displayName = 'EventRegisterAsWrapper';
}
