import type { PanoramixAbility } from '@/config/providers';
import type { RawRuleOf } from '@casl/ability';
import type { PackRule } from '@casl/ability/extra';
import type { Stored, UserEntry } from '@epitech/panoramix-types';
import { createAction } from '@reduxjs/toolkit';

export interface ILoginResponse {
  user: Stored<UserEntry>;
  jwt: string;
  permissions: PackRule<RawRuleOf<PanoramixAbility>>[];
}

export type AuthStateWithoutLogas = {
  user: ILoginResponse['user'] | null;
  jwt: ILoginResponse['jwt'] | null;
  permissions: ILoginResponse['permissions'];
};

export type AuthState = AuthStateWithoutLogas & {
  logasUser: AuthStateWithoutLogas | null;
};

export const tokenReceived = createAction<{ jwt: string }>('auth/tokenReceived');
export const logoutUser = createAction('auth/logoutUser');
