import type { EventRef } from '@epitech/panoramix-types';
import type { OptionBase } from 'chakra-react-select';
import { useCallback } from 'react';

import { sanitizeDatePayload } from '@/lib/helpers/dates.helpers';
import { type ISearchEventsRequest, useLazySearchEventsQuery } from '@/store/services/events';

export interface IEventOption extends OptionBase {
  label: string;
  value: EventRef;
}

const getEventOption = (eventRef: EventRef): IEventOption => ({
  label: eventRef.title,
  value: eventRef,
});

export function useEventSearch(params?: ISearchEventsRequest) {
  const [searchEvents] = useLazySearchEventsQuery();

  return useCallback(
    async (
      search: string,
      callback?: (options: IEventOption[]) => void | Promise<IEventOption[]>,
      limit?: number,
      offset?: number,
    ) => {
      const result = await searchEvents({ ...params, search, limit, offset }, true).unwrap();
      return result.map(sanitizeDatePayload<EventRef>).map(getEventOption);
    },
    [searchEvents, params],
  );
}
