import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Grid,
  Tag,
} from '@chakra-ui/react';
import { EventSample } from '@epitech/panoramix-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EventItem } from './EventItem';

interface ModuleItemProps {
  title: string;
  events?: EventSample[];
  oppened: boolean;
}

function _CityItem({ title, events, oppened }: ModuleItemProps) {
  const { t } = useTranslation('components/events');
  const [index, setIndex] = useState(oppened ? 0 : -1);

  const indexChange = useCallback((e: number) => setIndex(e), [setIndex]);

  return (
    <Accordion allowToggle index={index} onChange={indexChange}>
      <AccordionItem>
        <AccordionButton as="div" background="moduleViewCityBgColor">
          <Grid width="100%" as="span" textAlign="left" gridTemplateColumns={'2fr 1fr'} gap={4}>
            {title}
            <Tag backgroundColor="buttonBgColorDark" variant="roundXs">
              {t('created', { count: events?.length, nbEvent: events?.length })}
            </Tag>
          </Grid>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel mb={4} background="thirdBgColor">
          {events?.map((e: EventSample, index) => {
            return <EventItem key={index} start={e.start} end={e.end} title={e.title} id={e._id} />;
          })}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}

export const CityItem = React.memo(_CityItem);
