import type { RoomRef } from '@epitech/panoramix-types';
import type { OptionBase } from 'chakra-react-select';

export interface IRoomOption extends OptionBase {
  label: string;
  value: RoomRef;
}

export const getRoomOption = (room: RoomRef): IRoomOption => ({
  value: room,
  label: `${room.name}`,
});
