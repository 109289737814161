import { EventSlotMultipleEntry } from '@epitech/panoramix-types';
import React from 'react';
import { FiEdit2 } from 'react-icons/fi';

import { EventForceRegisterActionButton } from '@/components/events/force-register';
import { environment } from '@/config/environment';
import { BASE_OPACITY_TRANSITION } from '@/config/theme';

type ForceRegisterAppointmentMultipleActionButtonProps = {
  eventId: string;
  slot: EventSlotMultipleEntry;
  slotIdx: number;
  isLoading: boolean;
  isSlotFull: boolean;
  userType: 'users' | 'groups';
};

function _ForceRegisterAppointmentMultipleActionButton({
  eventId,
  slot,
  slotIdx,
  userType,
}: ForceRegisterAppointmentMultipleActionButtonProps) {
  return (
    <EventForceRegisterActionButton
      icon={FiEdit2}
      userType={userType}
      eventId={eventId}
      slot={slot}
      slotIdx={slotIdx}
      buttonProps={{
        opacity: 0,
        _groupHover: {
          opacity: 1,
        },
        transition: BASE_OPACITY_TRANSITION,
      }}
    />
  );
}

export const ForceRegisterAppointmentMultipleActionButton = React.memo(
  _ForceRegisterAppointmentMultipleActionButton,
);

if (environment.__DEV__) {
  ForceRegisterAppointmentMultipleActionButton.displayName =
    'ForceRegisterAppointmentMultipleActionButton';
}
