import { Text } from '@chakra-ui/react';
import type { ProjectGroupRef, UserRef } from '@epitech/panoramix-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { GroupItem } from '@/components/groups/GroupItem';
import { UserItem } from '@/components/users/UserItem';

interface EventRegisteredItem {
  item?: ProjectGroupRef | UserRef;
  isGroupMode: boolean;
}

function _EventRegisteredItem({ isGroupMode, item }: EventRegisteredItem) {
  const { t } = useTranslation('layouts/calendar');

  const emptyLabel = isGroupMode ? t('no_group_linked') : t('no_user_linked');

  return !item ? (
    <Text>{emptyLabel}</Text>
  ) : 'usersRef' in item ? (
    <GroupItem value={item} triggerSize="xs" />
  ) : (
    <UserItem value={item} avatarSize="xs" />
  );
}

export const EventRegisteredItem = React.memo(_EventRegisteredItem);
