import { Alert, AlertIcon, VStack } from '@chakra-ui/react';
import type { RoomRef } from '@epitech/panoramix-types';
import { EventSample } from '@epitech/panoramix-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { sanitizeDatePayload } from '@/lib/helpers/dates.helpers';

import type { EventWarningPayload } from '../types';
import { getRoomsConflictingFromEvents } from './helpers';
import { EventRoomsWarningList } from './List';

interface IEventRoomsWarningProps {
  eventsConflicting: EventWarningPayload['eventsConflicting'];
  selectedRooms?: RoomRef[];
}

function _EventRoomsWarning({
  eventsConflicting: rawEventsConflicting,
  selectedRooms,
}: IEventRoomsWarningProps) {
  const { t } = useTranslation('components/warnings');

  const eventsConflicting = rawEventsConflicting.map(sanitizeDatePayload<EventSample>);
  const conflictingRooms = useMemo(
    () => getRoomsConflictingFromEvents(eventsConflicting, selectedRooms),
    [selectedRooms, eventsConflicting],
  );

  if (!selectedRooms?.length) {
    return null;
  }

  return (
    <VStack spacing={2} w="full" align="stretch">
      <Alert status="warning" variant="subtle" borderTopRadius="md" boxShadow="inner">
        <AlertIcon />
        {t('edit_room_warning', {
          count: eventsConflicting.length,
          roomNames: conflictingRooms.map(room => room.name).join(', '),
        })}
        .
      </Alert>
      <EventRoomsWarningList events={eventsConflicting} conflictingRooms={conflictingRooms} />
    </VStack>
  );
}

export const EventRoomsWarning = React.memo(_EventRoomsWarning);
