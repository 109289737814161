import { Flex, StackProps, Text } from '@chakra-ui/react';
import { RoomRef } from '@epitech/panoramix-types';
import React from 'react';

export type RoomItemProps = {
  value: RoomRef;
} & StackProps;

function _RoomItem({ value: room, spacing = 2, ...stackProps }: RoomItemProps): React.ReactElement {
  const formattedTitle = `${room.name}`;
  return (
    <Flex gap={spacing} alignItems="center" {...stackProps}>
      <Text textTransform="capitalize">{formattedTitle}</Text>
    </Flex>
  );
}

export const RoomItem = React.memo<RoomItemProps>(_RoomItem);
