import type { IEventType, ProjectGroupRef, UserRef } from '@epitech/panoramix-types';
import { createContext } from 'react';

interface IEventDetailsContext {
  canUpdate: boolean;
  canDelete: boolean;
  canViewHistory: boolean;
  canViewHistoryLog: boolean;
  canRegister: boolean;
  canForceRegisterUsers: boolean;
  canForceRegisterStaffs: boolean;
  canForceRegisterRooms: boolean;
  toRegister?: UserRef | ProjectGroupRef;
  isAlreadyRegistered: boolean;
  isGroupMode: boolean;
  eventId: string;
  type: IEventType;
  title: string;
  seats?: number;
}

const EventDetailsContext = createContext<IEventDetailsContext | undefined>(undefined);

export default EventDetailsContext;
